import { TextVariant } from "@/types/text-variants";
import { Skeleton, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  Content,
  Line,
  SkeletonLoaderRow,
  TopRow,
} from "../../pages/Dashboard/Completed/Completed.styled";
import PageContentTableRow from "./PageContentTableRow";

const DeviceColumnCounts = {
  mobile: 2,
  tablet: 3,
};

const PageContentTable = ({
  isMobile,
  objects,
  selectRow,
  selected,
  setSelected,
  toggleSelectedObject,
  toggleAllSelected,
  values,
  idValue,
  checkboxSelectors = true,
  isLoading,
  perPage,
}) => {
  const isTablet = useMediaQuery("(max-width: 1200px)");

  if (isMobile) {
    values = values?.map((value) => value.slice(0, DeviceColumnCounts.mobile));
  } else if (isTablet) {
    values = values?.map((value) => value.slice(0, DeviceColumnCounts.tablet));
  }

  return (
    <Content id="completed_table">
      {
        <>
          <TopRow>
            {checkboxSelectors && <th></th>}
            {values?.at(0)?.map((value) => (
              <th key={value.label}>
                <Text variant={TextVariant.Body}>{value.label}</Text>
              </th>
            ))}
            <Line></Line>
          </TopRow>
        </>
      }

      {isLoading && (
        <>
          {Array.from(Array(perPage ?? 5).keys()).map((i) => {
            return (
              <SkeletonLoaderRow key={i}>
                {Array.from(Array(values?.at(0)?.length ?? 5).keys()).map(
                  (j) => {
                    return (
                      <td
                        key={j}
                        style={{
                          padding: "18px 10px",
                        }}
                      >
                        <Skeleton height={10} mt={6} radius="xl" />
                      </td>
                    );
                  },
                )}
              </SkeletonLoaderRow>
            );
          })}
        </>
      )}
      {!isLoading &&
        objects &&
        objects.map((object, index) => {
          return (
            <PageContentTableRow
              selectRow={selectRow}
              object={object}
              key={index}
              selected={selected}
              setSelected={setSelected}
              toggleSelectedObject={toggleSelectedObject}
              values={values[index]}
              objectId={object[idValue]}
              checkboxSelectors={checkboxSelectors}
              isTablet={isTablet}
            ></PageContentTableRow>
          );
        })}
    </Content>
  );
};

export default PageContentTable;
