import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { GetUsers } from "../../../../../server/src/types";

import { get } from "../request-helpers";

/**
 * GET /user
 */
export const useUserList = (query?: GetUsers["query"]) => {
  return useQuery({
    queryKey: ["clientList", query],
    queryFn: () => get<GetUsers>("/user", query),
    placeholderData: keepPreviousData,
  });
};
