import { TextVariant } from "@/types/text-variants";
import { Flex, Text, Tooltip } from "@mantine/core";
import { Row } from "../../pages/Dashboard/Completed/Completed.styled";

const PageContentTableRow = ({
  object,
  selectRow,
  toggleSelectedObject,
  selected,
  values,
  objectId,
  checkboxSelectors,
}) => {
  return (
    <Row onClick={() => selectRow(object)}>
      {checkboxSelectors && (
        <td className={"checkbox"}>
          <button
            style={{
              border: "none",
              background: "none",
            }}
            onClick={(e) => {
              e.stopPropagation();
              toggleSelectedObject(objectId);
            }}
          >
            <input
              type={"checkbox"}
              checked={selected?.find((c) => c.id === objectId)?.isSelected}
            />
          </button>
        </td>
      )}
      {values.map((value, index) => {
        return (
          <td key={index}>
            <Flex>
              {value.icons?.map((icon, index) => (
                <Tooltip key={index} label={icon?.tooltip}>
                  <div
                    style={{
                      display: "inline-block",
                      color: "grey",
                      marginRight: "5px",
                      height: "16px",
                    }}
                  >
                    {icon?.icon}
                  </div>
                </Tooltip>
              ))}
              <Text variant={TextVariant.Body}>{value.value}</Text>
            </Flex>
          </td>
        );
      })}
    </Row>
  );
};

export default PageContentTableRow;
