import { api } from "@/lib/api-client";
import { Button, Flex, Switch, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useEffect } from "react";
import type { GetClientFeatures } from "../../../../../../../../server/src/types";

const featuresOrder = [
  "SERVICE_BLOCK_BEFORE_AFTER",
  "SERVICE_ADVANCED_PRICING",
  "SERVICE_DYNAMIC_DURATION",
  "TIMEOFF",
  "RESOURCES",
  "RESOURCES_HIERARCHY",
  "PAYMENTS",
  "PRESCHEDULED",
  "USER_ROLES",
  "C_NOTIFICATION",
  "CHANGELOG",
  "BULK_NOTIF",
  "2FA",
  "INTEGRATION_FACEBOOK_CONVERSION_API",
];

const tempDisabledFeatures = ["RESOURCES_HIERARCHY"];

const FeaturesTab = ({ selectedClientId }: { selectedClientId: number }) => {
  const [features, setFeatures] = useState<
    GetClientFeatures["response"]["features"]
  >([]);

  const { data } = api.admin.useClientFeatures({ clientId: selectedClientId });

  const { mutateAsync: saveFeatures } =
    api.admin.useSaveClientFeatures(selectedClientId);

  const handleSaveFeatures = async () => {
    try {
      if (!features) return;
      await saveFeatures(features);

      notifications.show({
        message: "Posodobljeno",
      });
    } catch (e) {
      notifications.show({
        message: "Napaka pri shranjevanju",
        color: "red",
      });
    }
  };

  useEffect(() => {
    if (features.length > 0) return;
    if (!data) return;

    setFeatures(
      data.features.filter((f) => !tempDisabledFeatures.includes(f.featureId)),
    );
  }, [data]);

  return (
    <Flex
      style={{ border: "1px solid rgb(234, 234, 234)", borderRadius: "8px" }}
      p={15}
      mt={15}
      direction={"column"}
      gap={5}
    >
      {features
        .sort((a, b) => {
          if (featuresOrder.indexOf(a.featureId) === -1) return 1;
          if (featuresOrder.indexOf(b.featureId) === -1) return -1;

          return (
            featuresOrder.indexOf(a.featureId) -
            featuresOrder.indexOf(b.featureId)
          );
        })
        .map((feature, i) => (
          <>
            <Flex gap={20} key={i}>
              <Switch
                checked={feature.enabled}
                onChange={(e) => {
                  setFeatures((prev) => {
                    if (!prev) return prev;

                    const newFeatures = [...prev];
                    const id = newFeatures.findIndex(
                      (f) => f.featureId === feature.featureId,
                    );

                    newFeatures[id].enabled = e.target.checked;
                    return newFeatures;
                  });
                }}
              ></Switch>
              <Text>{feature.name}</Text>
            </Flex>
          </>
        ))}
      <Button onClick={handleSaveFeatures} w={"fit-content"} mt={20}>
        Shrani
      </Button>
    </Flex>
  );
};

export default FeaturesTab;
