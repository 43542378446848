import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";

import "./App.css";
import "./styles/fonts.css";
import "./styles/colors.css";
import Login from "./pages/Auth/Login/Login";
import Dashboard, { PageOuter } from "./pages/Dashboard/Dashboard";
import { getInfoFromToken } from "./apiCalls";
import { StateContext } from "./context/StateContext";

import LoadingSpinner from "./Components/Dashboard/LoadingSpinner/LoadingSpinner";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";

import * as Sentry from "@sentry/react";
import useAccountInfo from "./hooks/useAccountInfo";
import { api, queryClient } from "./lib/api-client";
import useUserPermissionsStore from "./stores/useUserPermissionsStore";
import usePreferredLanguageStore from "./stores/usePreferredLanguageStore";
import { Register } from "./pages/Auth/Register/Register";
import { ProductFruits } from "react-product-fruits";

import "dayjs/locale/sl";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/hr";
import "dayjs/locale/it";
import "dayjs/locale/es";
import "dayjs/locale/sr";

import { t } from "@lingui/macro";
import { notifications } from "@mantine/notifications";
import { usePostHog } from "posthog-js/react";
import { Helmet } from "react-helmet-async";
import { LuAlertTriangle } from "react-icons/lu";
import { DatesProvider } from "@mantine/dates";

Sentry.init({
  enabled: import.meta.env.VITE_SENTRY == "true" || false,
  dsn: "https://6b691f6f9e2a40a68bc0a08faa0af6f2@o4503972059086848.ingest.sentry.io/4504764321759232",
  integrations: [
    Sentry.replayIntegration(),

    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  const { handleStateChange } = useContext(StateContext);

  const [clientState, setClientState] = useState({
    isAuth: false,
    isLoading: true,
    onboardingDone: false,
    token: "",
  });

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const posthog = usePostHog();

  const { mutateAsync: verifyToken } = api.auth.useVerifyTempToken();

  const { setAccountInfo, accountInfo } = useAccountInfo((state) => state);

  const initLoadPermissions = useUserPermissionsStore(
    (state) => state.initLoadPermissions,
  );

  const { loadPreferredLanguage, setPreferredLanguage, preferredLanguage } =
    usePreferredLanguageStore((state) => state);

  const {
    data: serverAppVersionData,
    error: serverAppVersionError,
    isSuccess: isServerAppVersionSuccess,
  } = api.app.useGetAppVersion();

  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const versionGracePeriodSeconds = 5 * 60; // 5 minutes in seconds

  const calculateVersionGracePeriod = () => {
    const newVersionDetectedTime = localStorage.getItem(
      "newVersionDetectedTime",
    );

    if (!newVersionDetectedTime) return versionGracePeriodSeconds;

    const newVersionDetectedDate = new Date(newVersionDetectedTime);
    const currentDate = new Date();

    const diff = currentDate.getTime() - newVersionDetectedDate.getTime();
    const diffSeconds = Math.floor(diff / 1000);

    console.log("diffSeconds", versionGracePeriodSeconds - diffSeconds);

    return versionGracePeriodSeconds - diffSeconds;
  };

  const setTimers = (gracePeriod: number) => {
    const notificationTimeout = setTimeout(() => {
      notifications.show({
        message: t`Nova različica aplikacije je na voljo... Stran se bo samodejno osvežila čez 5 sekund`,
        color: "orange",
        autoClose: false,
        icon: <LuAlertTriangle />,
      });

      const reloadTimeout = setTimeout(() => {
        localStorage.removeItem("newVersionDetectedTime");
        window.location.reload();
      }, 5000);
      timeoutsRef.current.push(reloadTimeout);
    }, gracePeriod * 1000);

    timeoutsRef.current.push(notificationTimeout);
  };

  const checkVersion = () => {
    const clientVersion = import.meta.env.VITE_APP_VERSION;
    console.log(
      "Client version: ",
      clientVersion,
      serverAppVersionData?.version,
    );

    if (serverAppVersionError || !isServerAppVersionSuccess) {
      if (serverAppVersionError) {
        Sentry.captureException(serverAppVersionError);
      }
      return;
    }

    if (serverAppVersionData.version !== clientVersion) {
      if (localStorage.getItem("newVersionDetectedTime")) return;

      console.log("New version available");

      localStorage.setItem("newVersionDetectedTime", new Date().toISOString());

      const gracePeriod = calculateVersionGracePeriod();
      setTimers(gracePeriod);
    } else {
      if (localStorage.getItem("newVersionDetectedTime")) {
        localStorage.removeItem("newVersionDetectedTime");

        timeoutsRef.current.forEach((timeout) => {
          clearTimeout(timeout);
        });

        timeoutsRef.current = [];
      }
    }
  };

  /**
   * Check for new version every minute
   */
  useEffect(() => {
    const newVersionDetectedTime = localStorage.getItem(
      "newVersionDetectedTime",
    );
    if (newVersionDetectedTime) {
      const gracePeriod = calculateVersionGracePeriod();
      console.log("gracePeriod", gracePeriod);
      setTimers(Math.max(gracePeriod, 0));
    }

    checkVersion();

    return () => {
      timeoutsRef.current.forEach((timeout) => {
        clearTimeout(timeout);
      });

      timeoutsRef.current = [];
    };
  }, [serverAppVersionData]);

  const SentryRoutes = useMemo(
    () => Sentry.withSentryReactRouterV6Routing(Routes),
    [],
  );

  const checkAuth = async () => {
    setClientState({ ...clientState, isLoading: true });

    const token = localStorage.getItem("usr_token") || "";

    if (token !== "") {
      try {
        const verify = await getInfoFromToken(token);
        await initLoadPermissions();

        // set global account info state
        setAccountInfo(verify.data[0]);
        handleStateChange(verify.data);

        loadPreferredLanguage();

        Sentry.setContext("user", {
          clientId: verify.data[0].clientId,
          userId: verify.data[0].userId,
          pricingTierId: verify.data[0].FK_pricingTierId,
          onboardingDone: verify.data[0].onboardingDone,
        });
        posthog?.identify(verify.data[0].userId, {
          name: verify.data[0].userName,
          lastName: verify.data[0].userLastName,
          email: verify.data[0].userEmail,
          gsm: verify.data[0].userPhone,
          clientId: verify.data[0].clientId,
        });
        posthog?.group("client", verify.data[0].clientId);

        Sentry.setTag("clientId", verify.data[0].clientId);

        setClientState({
          ...clientState,
          isAuth: true,
          onboardingDone: verify.data[0].onboardingDone,
          isLoading: false,
          token: token,
        });
      } catch (err) {
        setClientState({
          ...clientState,
          isAuth: false,
          isLoading: false,
          onboardingDone: false,
        });
        posthog?.reset();

        localStorage.removeItem("usr_token");
        window.location.reload();
      }
    } else {
      //if no token check if t query param is set

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      const tempToken = params.get("t");

      if (tempToken) {
        try {
          const { token } = await verifyToken({ t: tempToken });

          localStorage.setItem("usr_token", token);

          window.location.href = "/";
        } catch (err) {
          console.log(err);
        }
      } else {
        setPreferredLanguage({
          userPreferredLanguage:
            params.get("lang") ||
            preferredLanguage.userPreferredLanguage ||
            "sl",
          clientPreferredLanguage:
            params.get("lang") ||
            preferredLanguage.clientPreferredLanguage ||
            "sl",
        });
      }

      setClientState({ ...clientState, isLoading: false });
    }
  };

  useEffect(() => {
    checkAuth();

    queryClient.invalidateQueries();

    axios.defaults.headers.common["x-app-version"] =
      import.meta.env.VITE_APP_VERSION;
  }, []);

  useEffect(() => {
    const lang =
      preferredLanguage.userPreferredLanguage ??
      preferredLanguage.clientPreferredLanguage ??
      "sl";
    axios.defaults.headers.common["x-custom-locale"] = lang;
  }, [preferredLanguage]);

  const setOnboardingDone = (value: boolean) => {
    setClientState((prevState) => ({
      ...prevState,
      onboardingDone: value,
      isLoading: false,
    }));

    if (value === true) {
      Sentry.captureMessage("Onboarding completed!");
    }
  };

  const setisAuth = (value: boolean) => {
    setClientState((prevState) => ({
      ...prevState,
      isAuth: value,
      isLoading: false,
    }));
  };

  const setUserToken = (value: string) => {
    setClientState((prevState) => ({
      ...prevState,
      token: value,
      isLoading: false,
    }));
  };

  const redirect = async () => {
    const [, , hash] = pathname.split("/");
    const queryFormHash = searchParams.get("form");

    const preselectedLocation = searchParams.get("location");
    const preselectedService = searchParams.get("service");
    const language = searchParams.get("lang");

    const response = await api.redirect.getRedirectLink({
      hash: hash ?? queryFormHash,
      preselectedLocation: preselectedLocation
        ? Number(preselectedLocation)
        : undefined,
      preselectedService: preselectedService
        ? Number(preselectedService)
        : undefined,
      language: language as "sl" | "en" | "de" | "hr" | "it" | undefined,
    });

    if (response) {
      window.location.href = response.redirectUrl;
    }
  };

  const redirectReschedule = async () => {
    const customerHash = searchParams.get("customer") || searchParams.get("c");

    if (!customerHash) return;

    const response = await api.redirect.getRedirectRescheduleLink({
      customerHash,
    });

    if (response) {
      window.location.href = response.newUrl;
    }
  };

  if (clientState.isLoading) {
    return (
      <PageOuter isLoading={true}>
        <LoadingSpinner />
      </PageOuter>
    );
  }

  if (pathname.includes("/form")) {
    redirect();

    return null;
  }

  if (
    pathname.split("/").includes("r") ||
    pathname.split("/").includes("reschedule")
  ) {
    redirectReschedule();

    return null;
  }

  // not authenticated
  if (!clientState.isAuth) {
    return (
      <SentryRoutes>
        <Route path={"/forgot-password"} element={<ForgotPassword />} />
        <Route path={"/reset-password"} element={<ResetPassword />} />
        <Route
          path={"/login"}
          element={
            <Login
              setOnboardingDone={setOnboardingDone}
              setAuth={setisAuth}
              setUserToken={setUserToken}
            />
          }
        />
        <Route path={"/register"} element={<Register />} />

        <Route path={"*"} element={<Navigate to={"/login"} />} />
      </SentryRoutes>
    );
  }

  if (clientState.token == "") return <LoadingSpinner />;

  // ----- IS AUTHENTICATED -----
  return (
    <>
      {" "}
      <Helmet>
        <title>
          Lime booking
          {accountInfo?.businessName ? `- ${accountInfo?.businessName}` : "a"}
        </title>
      </Helmet>
      {/* <ProductFruits
        lifeCycle="unmount"
        workspaceCode="wd8e6Z7nu0mssKIR"
        language={
          preferredLanguage.userPreferredLanguage ??
          preferredLanguage.clientPreferredLanguage ??
          "sl"
        }
        user={{
          firstname: accountInfo?.name,
          lastname: accountInfo?.lastName,
          email: accountInfo?.email,
          username: accountInfo
            ? `${accountInfo?.name} ${accountInfo?.lastName}`
            : "No name",
        }}
      /> */}
      <div className="App">
        <SentryRoutes>
          <Route
            path={"/dashboard/*"}
            element={
              <DatesProvider
                settings={{
                  locale:
                    preferredLanguage.userPreferredLanguage ??
                    preferredLanguage.clientPreferredLanguage ??
                    "sl",
                }}
              >
                <Dashboard />
              </DatesProvider>
            }
          />
          <Route path={"*"} element={<Navigate to={"/dashboard/*"} />} />
        </SentryRoutes>
      </div>
    </>
  );
}

export default App;
