import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useDebouncedState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  deleteCustomerWithId,
  getCustomerData,
  getCustomerList,
  postMergeCustomers,
} from "../apiSchedule";

export const useCustomers = () => {
  useLingui();
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const [searchQuery, setSearchQuery] = useDebouncedState("", 500);

  const [perPage, setPerPage] = useState(10);
  const [paginationPage, setPaginationPage] = useState(1);
  const [pagination, setPagination] = useState(1);

  const [mergeAlert, setMergeAlert] = useState({
    opened: false,
    data: {},
  });

  const [sortBy, setSortBy] = useState("lastVisitDesc");

  useEffect(() => {
    updateCustomers();
  }, []);

  useEffect(() => {
    updateCustomers();
  }, [paginationPage, searchQuery, perPage, sortBy]);

  useEffect(() => {
    // check if every customer in selected.isSelect is true
    if (selectedCustomers.every((customer) => customer.isSelected)) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }

    if (paginationPage > pagination) {
      setPaginationPage(1);
    }
  }, [selectedCustomers, customers]);

  useEffect(() => {
    if (mergeAlert.opened) {
      const data = selectedCustomers
        .filter((customer) => customer.isSelected)
        .at(0);

      const comment = selectedCustomers
        .filter((customer) => customer.isSelected)
        .at(0).comment;

      selectedCustomers
        .filter((customer) => customer.isSelected)
        .forEach((customer) => {
          if (customer.comment?.length > comment?.length) {
            data.comment = customer.comment;
          }
        });

      setMergeAlert((prevState) => ({ ...prevState, data: data }));
    }
  }, [mergeAlert.opened]);

  const toggleSelectedCustomer = (customerId) => {
    const temp = [...selectedCustomers];

    const customer = temp.find((c) => c.id === customerId);

    if (customer) {
      customer.isSelected = !customer.isSelected;
    } else {
      temp.push({ id: customerId, isSelected: true });
    }

    setSelectedCustomers(temp);
  };

  const toggleAllSelected = () => {
    const temp = [...selectedCustomers];

    if (temp.every((c) => c.isSelected)) {
      temp.forEach((c) => (c.isSelected = false));
    } else {
      temp.forEach((c) => (c.isSelected = true));
    }

    setSelectedCustomers(temp);
  };

  const mergeCustomers = async (data) => {
    const req = await postMergeCustomers(
      selectedCustomers.filter((c) => c.isSelected).map((c) => Number(c.id)),
      data,
    );

    if (req.status === 200) {
      notifications.show({
        title: t`Uspešno`,
        message: t`Uporabniki so bili uspešno združeni`,
      });

      setMergeAlert({
        opened: false,
        data: {},
      });
    } else {
      notifications.show({
        title: t`Napaka`,
        message: t`Napaka pri združevanju uporabnikov: ${req.data.message}`,
        color: "red",
      });

      setMergeAlert({
        opened: false,
        data: {},
      });
    }

    updateCustomers();
  };

  const openMergeCustomersPopup = () => {
    setMergeAlert({
      ...mergeAlert,
      opened: true,
    });
  };

  const closeMergeCustomersPopup = () => {
    setMergeAlert({
      data: {},
      opened: false,
    });
  };

  const updateCustomers = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const cust = await getCustomerList(
        paginationPage,
        searchQuery,
        perPage,
        sortBy,
      );
      if (cust.status === 200) {
        if (cust.data.pagination) {
          setPagination(cust.data.pagination);
        }

        setCustomers(cust.data.customers);
        setSelectedCustomers(
          cust.data.customers.map((c) => {
            return {
              id: c.customerId,
              ...c,
              isSelected: false,
            };
          }),
        );
      } else {
        setError({ status: cust.status, message: cust.statusText });
      }
    } catch (error) {
      setError({ status: 500, message: error.message });
    }

    setIsLoading(false);
  };

  const deleteCustomer = async (customerId) => {
    setError(null);
    setIsLoading(true);
    const token = localStorage.getItem("usr_token");
    try {
      const response = await deleteCustomerWithId(customerId, token);
    } catch (error) {
      setError({ status: 500, message: error.message });
    }
    setIsLoading(false);
  };

  const refreshCustomerData = async (customerId) => {
    setError(null);

    const token = localStorage.getItem("usr_token");
    const customer = await getCustomerData(
      customerId ?? customerData?.customerId,
      token,
    );

    if (customer.status === 200) {
      setCustomerData({
        ...customer.data,
        birthday: customer.data.birthday
          ? dayjs(customer.data.birthday).toDate()
          : null,
      });
    } else {
      setError({ status: customer.status, message: customer.statusText });
    }
  };

  return {
    refreshCustomerData,
    customers,

    customerData,
    isLoading,
    error,
    setCustomerData,
    deleteCustomer,
    updateCustomers,
    selected: selectedCustomers,
    setSelected: setSelectedCustomers,
    toggleSelectedCustomer,
    toggleAllSelected,
    mergeCustomers,
    openMergeCustomersPopup,
    closeMergeCustomersPopup,
    mergeAlert,
    setMergeAlert,
    allSelected,

    paginationPage,
    setPaginationPage,
    pagination,

    searchQuery,
    setSearchQuery,

    perPage,
    setPerPage,

    sortBy,
    setSortBy,
  };
};
