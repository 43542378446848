import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Accordion, Flex, Space, Text, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { RiCalendarTodoLine, RiTimeLine } from "react-icons/ri";
import { useScheduleStore } from "../../Stores/useScheduleStore";
import classes from "./BulkDaysList.module.css";
import DayScheduleSettings from "./DayScheduleSettings";

const BulkDaysList = ({ data, updateData, viewType, holidays = [] }) => {
  const { i18n } = useLingui();
  const locale = i18n.locale;

  const { selectedScheduleId, schedules } = useScheduleStore((state) => state);

  const selectedSchedule = schedules.find(
    (schedule) => schedule.scheduleId === selectedScheduleId,
  );

  if (!data) return null;

  const scheduleDateFrom = dayjs(selectedSchedule.date_from)
    .utc(false)
    .format("YYYY-MM-DD");
  const scheduleDateTo = dayjs(selectedSchedule.date_to)
    .utc(false)
    .format("YYYY-MM-DD");

  return (
    <>
      <Text fw={500} size={"sm"}>
        <Trans>Delovni čas</Trans>
      </Text>
      <Space h={10} />
      <Accordion
        // maw={420}
        mx="auto"
        variant="filled"
        defaultValue="customization"
        classNames={classes}
        className={classes.root}
      >
        {/* generate days from monday to friday */}
        {data?.shifts?.map((shift) => {
          const dateObject = dayjs(shift.date);
          const date = dateObject.format("YYYY-MM-DD");
          const dayName = capitalize(dateObject.locale(locale).format("dddd"));
          const dayDate = dateObject.format("DD. MM.");

          const isHoliday = holidays.find((h) => {
            return h.date === date;
          });

          const isDisabled =
            date < scheduleDateFrom || date > scheduleDateTo; /*||
            date < dayjs().startOf("day").format("YYYY-MM-DD")*/

          return (
            <Accordion.Item key={shift.date} value={shift.date}>
              <Accordion.Control disabled={isDisabled}>
                <Flex direction={"row"} gap={"10px"} justify={"space-between"}>
                  <Flex direction={"column-reverse"}>
                    <Flex gap={10} align={"center"}>
                      <Text c={"#344054"} inline span>
                        {dayName}
                      </Text>
                      {isHoliday && (
                        <Tooltip
                          label={isHoliday.name}
                          color="#7e96cd"
                          withArrow
                        >
                          <div className="holiday_span">{isHoliday?.name}</div>
                        </Tooltip>
                      )}
                    </Flex>
                    <Text inline span>
                      {viewType === "month" && (
                        <Text c={"#344054"} inline span size={"xs"}>
                          {dayDate}
                        </Text>
                      )}
                    </Text>
                  </Flex>
                  <Flex align={"center"}>
                    {shift?.is_working &&
                      shift?.time_from &&
                      shift?.time_to && (
                        <Text
                          ml={"sm"}
                          size={"xs"}
                          inline
                          span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                          c={"#344054"}
                        >
                          <RiTimeLine size={"13px"} />
                          <span>
                            {shift?.time_from} - {shift?.time_to}
                          </span>
                        </Text>
                      )}
                    {shift?.is_form_working &&
                      shift?.form_time_from &&
                      shift?.form_time_to && (
                        <Text
                          ml={"sm"}
                          size={"xs"}
                          inline
                          span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                          c={"#344054"}
                        >
                          <RiCalendarTodoLine size={"13px"} />
                          <span>
                            {shift?.form_time_from} - {shift?.form_time_to}
                          </span>
                        </Text>
                      )}
                  </Flex>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <DayScheduleSettings
                  data={shift}
                  updateData={updateData}
                  date={shift.date}
                />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
};

export default BulkDaysList;
