import { LimePagination } from "@/Components/LimePagination";
import LimePhoneInputField from "@/Components/LimePhoneInputField";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Flex,
  Modal,
  Select,
  Space,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MdOutlineAdd } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import {
  RiGitMergeLine,
  RiGroupFill,
  RiMailSendLine,
  RiSearch2Line,
} from "react-icons/ri";
import EmptyState from "../../../Components/EmptyState";
import LimeDrawer from "../../../Components/LimeDrawer/LimeDrawer";
import PageContentTable from "../../../Components/PageContentTable/PageContentTable";
import { useCustomers } from "../../../hooks/useCustomers";
import useFeature from "../../../hooks/useFeature";
import { BulkSMSModal } from "./BulkSMSModal";
import { Actions, ActionsIcons, ActionsRow } from "./Completed.styled";
import CustomerCard from "./CustomerCard/CustomerCard";
import useModalCustomer from "./useModalCustomer";

const Completed = () => {
  const {
    customers,
    isLoading,
    updateCustomers,
    selected,
    setSelected,
    toggleSelectedCustomer,
    toggleAllSelected,
    mergeCustomers,
    allSelected,
    paginationPage,
    setPaginationPage,
    pagination,
    searchQuery,
    setSearchQuery,
    perPage,
    setPerPage,
    sortBy,
    setSortBy,
  } = useCustomers();

  const mergeForm = useForm({
    initialValues: {
      name: null,
      lastName: null,
      gsm: null,
      email: null,
    },
  });

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  useLingui();
  const [form, modalOpened, toggleModal, setModalOpened, submitCustomerForm] =
    useModalCustomer();

  const [mergeModalOpened, { open: openMergeModal, close: closeMergeModal }] =
    useDisclosure(false);
  const [
    bulkSMSModalOpened,
    { open: openBulkSMSModal, close: closeBulkSMSModal },
  ] = useDisclosure(false);

  const { isFeatureEnabled } = useFeature();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const selectedUserId = params.get("user");

    if (selectedUserId) {
      selectRow(selectedUserId);

      window.history.replaceState({}, "", `${window.location.pathname}`);
    }
  }, []);

  const selectRow = (customerId) => {
    setSelectedCustomerId(customerId);
  };

  return (
    <>
      <Modal
        opened={mergeModalOpened}
        onClose={closeMergeModal}
        title={t`Podatki združenega uporabnika`}
        centered
      >
        <form
          style={{ display: "contents" }}
          onSubmit={mergeForm.onSubmit((values) => {
            mergeCustomers(values);
            closeMergeModal();
          })}
        >
          <Flex direction={"column"} gap={"md"}>
            <Select
              w={"100%"}
              ta={"left"}
              label={"Ime"}
              data={selected
                .filter((c) => c.isSelected)
                .filter(
                  (customer, index, self) =>
                    index === self.findIndex((t) => t.name === customer.name),
                )
                .map((customer, index) => ({
                  label: customer.name,
                  value: customer.name,
                }))}
              {...mergeForm.getInputProps("name")}
            ></Select>
            <Select
              w={"100%"}
              ta={"left"}
              label={"Priimek"}
              data={selected
                .filter((c) => c.isSelected)
                .filter(
                  (customer, index, self) =>
                    index ===
                    self.findIndex((t) => t.lastName === customer.lastName),
                )
                .map((customer, index) => ({
                  label: customer.lastName,
                  value: customer.lastName,
                }))}
              {...mergeForm.getInputProps("lastName")}
            ></Select>
            <Select
              w={"100%"}
              ta={"left"}
              label={"Telefonska številka:"}
              data={selected
                .filter((c) => c.gsm != null && c.gsm !== "")
                .filter((c) => c.isSelected)
                .filter(
                  (customer, index, self) =>
                    index === self.findIndex((t) => t.gsm === customer.gsm),
                )
                .map((customer, index) => ({
                  label: customer.gsm,
                  value: customer.gsm?.toString() ?? "",
                }))}
              {...mergeForm.getInputProps("gsm")}
              allowDeselect={false}
            ></Select>
            <Select
              w={"100%"}
              ta={"left"}
              label={"E-pošta:"}
              data={selected
                .filter((c) => c.email != null && c.email !== "")
                .filter((c) => c.isSelected)
                .filter(
                  (customer, index, self) =>
                    index === self.findIndex((t) => t.email === customer.email),
                )
                .map((customer, index) => ({
                  label: customer.email,
                  value: customer.email,
                }))}
              {...mergeForm.getInputProps("email")}
            ></Select>

            <Flex justify={"flex-end"} gap={"md"}>
              <Button
                variant={"white"}
                onClick={() => {
                  closeMergeModal();
                }}
              >
                <Trans>Prekliči</Trans>
              </Button>
              <Button type="submit">
                <Trans>Združi</Trans>
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>

      <LimeDrawer
        opened={modalOpened}
        onClose={modalOpened ? toggleModal : null}
        className="modal_customer"
        title={t`Dodaj stranko`}
        buttons={{
          save: (
            <Button type="submit">
              <Trans>Dodaj</Trans>
            </Button>
          ),
          cancel: (
            <Button variant="outline" onClick={toggleModal}>
              <Trans>Prekliči</Trans>
            </Button>
          ),
        }}
        form={{
          form,
          onFormSubmit: (values) => {
            submitCustomerForm(values);
            updateCustomers();
            toggleModal();
          },
        }}
      >
        <Flex direction={"column"} gap={20}>
          <TextInput
            id={"name"}
            label={t`Ime`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("name")}
            required={true}
          />
          <TextInput
            id={"surname"}
            label={t`Priimek`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("lastName")}
            required={true}
          />

          <TextInput
            id={"email"}
            label={t`E-pošta`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("email")}
          />
          <div>
            <Text size={"sm"} fw={500} c={"#212529"}>
              <Trans>Telefonska številka</Trans>
            </Text>
            <LimePhoneInputField
              {...form.getInputProps("gsm")}
              id={"tel"}
              filled
              form
              autocomplete="do-not-autofill"
            />
          </div>
          <Textarea
            id={"comment"}
            label={t`Komentar`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("notes")}
          />
        </Flex>
      </LimeDrawer>

      <PageHeader
        title={t`Pregled strank`}
        actionButton={{
          onClick: () => {
            toggleModal();
          },
          icon: <MdOutlineAdd size={"1.3rem"} />,
          label: t`Dodaj stranko`,
        }}
        subHeader={{
          tabs: [
            {
              label: t`Stranke`,
              value: "customers",
              rightSection: (
                <Flex align={"center"} gap={"sm"}>
                  <Text variant="caption" c="var(--secondary-text)">
                    <Trans>Razvrsti po:</Trans>
                  </Text>

                  <Select
                    data={[
                      {
                        label: t`Imenu A-Z`,
                        value: "nameAsc",
                      },
                      {
                        label: t`Imenu Z-A`,
                        value: "nameDesc",
                      },
                      {
                        label: t`Priimku A-Z`,
                        value: "lastNameAsc",
                      },
                      {
                        label: t`Priimku Z-A`,
                        value: "lastNameDesc",
                      },

                      {
                        label: t`Datumu zadnjega obiska (novejši najprej)`,
                        value: "lastVisitDesc",
                      },
                      {
                        label: t`Datumu zadnjega obiska (starejši najprej)`,
                        value: "lastVisitAsc",
                      },
                    ]}
                    value={sortBy}
                    onChange={(value) => {
                      setSortBy(value);
                    }}
                  />
                </Flex>
              ),
            },
          ],
        }}
      />

      <ActionsRow>
        <ActionsIcons>
          <Flex>
            <button
              style={{
                background: "none",
                border: "none",
              }}
              onClick={(e) => {
                toggleAllSelected();
              }}
              className={"action-button checkbox select-all"}
            >
              <input checked={allSelected} type={"checkbox"}></input>
              <span
                style={{
                  color: "#6C7B86",
                  fontSize: "0.8rem",
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  userSelect: "none",
                }}
              >
                <Trans>Označi vse</Trans>{" "}
                {selected.filter((s) => s.isSelected).length > 0 &&
                  `(${selected.filter((s) => s.isSelected).length})`}
              </span>
            </button>

            <Actions active={true} activeFull={true}>
              <div className={"active-buttons-transition-wrapper"}>
                {selected.filter((s) => s.isSelected).length < 6 &&
                  selected.filter((s) => s.isSelected).length > 0 && (
                    <button
                      style={{
                        border: "none",
                      }}
                      onClick={() => {
                        mergeForm.reset();

                        const firstSelected = selected.filter(
                          (c) => c.isSelected,
                        )[0];
                        mergeForm.setValues({
                          name: firstSelected.name,
                          lastName: firstSelected.lastName,
                          gsm: firstSelected.gsm,
                          email: firstSelected.email,
                        });

                        openMergeModal();
                      }}
                      className={"merge-action action-button"}
                    >
                      <RiGitMergeLine></RiGitMergeLine>
                      {!isMobile && (
                        <span>
                          <Trans>Združi izbrane</Trans>
                        </span>
                      )}
                    </button>
                  )}
                {isFeatureEnabled("BULK_NOTIF") &&
                  selected.filter((s) => s.isSelected).length > 0 && (
                    <button
                      style={{
                        border: "none",
                      }}
                      onClick={openBulkSMSModal}
                      className={"send-sms action-button"}
                    >
                      <RiMailSendLine></RiMailSendLine>
                      {!isMobile && (
                        <span>
                          <Trans>Pošlji SMS</Trans>
                        </span>
                      )}
                    </button>
                  )}
              </div>
            </Actions>
          </Flex>

          <TextInput
            placeholder={t`Iskanje`}
            defaultValue={searchQuery}
            leftSection={<RiSearch2Line style={{ color: "#6C7B86" }} />}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        </ActionsIcons>
      </ActionsRow>

      {!isLoading && customers.length === 0 && (
        <EmptyState
          title={t`Nimate še strank`}
          icon={<RiGroupFill fill={"#6c7b86"} size={"2rem"} />}
          description={t`Dodajte prvi termin, da bo na voljo pregled strank.`}
        />
      )}

      <PageContentTable
        toggleSelectedObject={toggleSelectedCustomer}
        isMobile={isMobile}
        objects={customers}
        selectRow={(customer) => selectRow(customer.customerId)}
        selected={selected}
        setSelected={setSelected}
        toggleAllSelected={toggleAllSelected}
        idValue={"customerId"}
        isLoading={isLoading}
        perPage={perPage}
        values={customers.map((c) => [
          {
            label: t`Ime`,
            value: c.name,
          },
          {
            label: t`Priimek`,
            value: c.lastName,
          },
          {
            label: t`Elektronski naslov`,
            value: c.email,
          },
          {
            label: t`Telefonska številka`,
            value: c.gsm,
            icons: [
              c.gsm &&
                c.formBlocked && {
                  icon: <MdBlock />,
                  tooltip: t`Telefonska številka blokirana na spletnem naročanju`,
                },
            ],
          },
          {
            label: t`Datum zadnjega obiska`,
            value: c.startTime
              ? dayjs(c.startTime).utc(false).format("DD. MM. YYYY HH:mm")
              : t`Še ni obiska`,
          },
        ])}
      ></PageContentTable>

      <Space h={"1rem"} />

      <LimePagination
        label={t`strank`}
        page={paginationPage}
        pageCount={pagination.pageCount}
        perPage={perPage}
        setPage={setPaginationPage}
        setPerPage={setPerPage}
      />

      <LimeDrawer
        opened={selectedCustomerId != null}
        onClose={
          selectedCustomerId
            ? () => {
                setSelectedCustomerId(null);
                updateCustomers();
              }
            : null
        }
        title={t`Kartica stranke`}
        size="fullScreen"
      >
        <CustomerCard
          customerId={selectedCustomerId}
          withTopPadding={false}
          handleClose={() => {
            setSelectedCustomerId(null);
            updateCustomers();
          }}
        />
      </LimeDrawer>

      <BulkSMSModal
        onToggle={(value) => (value ? openBulkSMSModal() : closeBulkSMSModal())}
        opened={bulkSMSModalOpened}
        selectedCustomerIds={selected
          .filter((s) => s.isSelected)
          .map((s) => s.customerId)}
      />
    </>
  );
};

export default Completed;
