import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  ActionIcon,
  Box,
  type BoxProps,
  Button,
  Divider,
  Drawer,
  Flex,
  Loader,
  Popover,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import React from "react";
import { BsThreeDots } from "react-icons/bs";
import { RiAddFill, RiArrowLeftLine } from "react-icons/ri";
import { extractSpacingProps } from "./LimeComponentUtils";
import { MobileSidebarToggle } from "./MobileSidebarToggle";

type AddButton = {
  label: string;
  onClick: () => void;
  identifier?: string;
};

export type SubPageRightSection = {
  title?: string;

  options: {
    label: string;
    onClick: () => void;
    color?: string;
    icon?: React.ReactNode;
    loading?: boolean;
    closeMenuOnClick?: boolean; // default true
  }[];
};

export type LimePageHeaderProps = {
  title: string;
  rightSection?: AddButton | React.ReactNode;

  subPage?: {
    title: string;
    onBackButtonClick: () => void;
    rightSection?: SubPageRightSection | React.ReactNode;
  };
};

const rightSectionIsAddButton = (
  rightSection: AddButton | React.ReactNode,
): rightSection is AddButton => {
  return (rightSection as AddButton).label !== undefined;
};

const subPageRightSectionIsCustomElement = (
  rightSection: SubPageRightSection | React.ReactNode,
): rightSection is React.ReactNode => {
  return typeof rightSection !== "object";
};

export const LimePageHeader = ({
  title,
  rightSection,
  subPage,
  ...boxProps
}: LimePageHeaderProps & BoxProps) => {
  const [settingsOpened, { open: openSettings, close: closeSettings }] =
    useDisclosure(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { spacingProps } = extractSpacingProps(boxProps);

  return (
    <>
      <Box
        {...spacingProps}
        px={isMobile ? undefined : "xl"}
        pt={0}
        w={"100%"}
        h={subPage && isMobile ? "40px" : "60px"}
      >
        <Flex
          w={"100%"}
          align={"center"}
          h={"100%"}
          justify={"space-between"}
          pos={"relative"}
          style={{
            borderBottom: "1px solid var(--divider)",
          }}
        >
          <Flex
            w={"100%"}
            align={isMobile ? "center" : "flex-end"}
            pb={isMobile ? 0 : "xs"}
            justify={isMobile ? undefined : "space-between"}
            px={isMobile ? "md" : undefined}
            gap={"md"}
            h={"100%"}
          >
            {isMobile && !subPage ? (
              <MobileSidebarToggle withoutRightMargin />
            ) : undefined}

            {subPage ? (
              <ActionIcon onClick={subPage?.onBackButtonClick}>
                <RiArrowLeftLine size={"1.1rem"} />
              </ActionIcon>
            ) : undefined}

            <Text variant={TextVariant.Heading}>{title}</Text>
          </Flex>

          {subPage ? (
            <>
              <Text
                pos={"absolute"}
                w={"100%"}
                ta={"center"}
                variant={
                  isMobile ? TextVariant.BodyEmphasized : TextVariant.Subheading
                }
                style={{ pointerEvents: "none" }}
              >
                {subPage.title}
              </Text>
            </>
          ) : undefined}

          <Box mr={isMobile && subPage ? "xl" : 0}>
            {buildSubPageRightSection({
              rightSection: subPage?.rightSection,
              isMobile: isMobile || false,
              settingsOpened,
              closeSettings,
              openSettings,
            }) || buildRightSection(rightSection, isMobile || false)}
          </Box>
        </Flex>

        {isMobile ? <Divider /> : undefined}
      </Box>

      <Drawer
        opened={(isMobile || false) && settingsOpened}
        onClose={closeSettings}
        position="bottom"
        title={
          !subPageRightSectionIsCustomElement(subPage?.rightSection) &&
          subPage?.rightSection?.title
            ? subPage?.rightSection?.title
            : "Nastavitve"
        }
        styles={{
          content: {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            height: "fit-content",
          },
          title: {
            fontWeight: 500,
            fontSize: "14px",
          },
        }}
      >
        {!subPageRightSectionIsCustomElement(subPage?.rightSection) &&
          subPage?.rightSection.options.map((option, index) => {
            return (
              <React.Fragment key={option.label}>
                <Button
                  leftSection={
                    option.icon != null ? (
                      <div
                        style={{ color: option.color || Color.SecondaryText }}
                      >
                        {option.icon}
                      </div>
                    ) : undefined
                  }
                  variant="white"
                  fullWidth
                  justify="flex-start"
                  onClick={option.onClick}
                  loading={option.loading}
                >
                  <Text
                    variant={TextVariant.Body}
                    c={option.color || Color.SecondaryText}
                  >
                    {option.label}
                  </Text>
                </Button>
                {
                  // Don't render divider after last element
                  index !==
                  (subPage?.rightSection as SubPageRightSection).options
                    .length -
                    1 ? (
                    <Divider my={"xs"} />
                  ) : undefined
                }
              </React.Fragment>
            );
          })}
      </Drawer>
    </>
  );
};

const buildSubPageRightSection = ({
  rightSection,
  isMobile,
  settingsOpened,
  closeSettings,
  openSettings,
}: {
  rightSection: SubPageRightSection | React.ReactNode;
  isMobile: boolean;
  settingsOpened: boolean;
  closeSettings: () => void;
  openSettings: () => void;
}): React.ReactNode => {
  if (!rightSection) return undefined;

  if (subPageRightSectionIsCustomElement(rightSection)) {
    return rightSection;
  }

  if (isMobile) {
    return (
      <ActionIcon
        // loading={isDeletingResourceGroup || isDeletingResource}
        onClick={openSettings}
      >
        <BsThreeDots />
      </ActionIcon>
    );
  }

  return (
    <Popover
      position="bottom-end"
      withArrow
      shadow="md"
      width={280}
      opened={settingsOpened}
      onChange={(opened) => {
        if (!opened) {
          closeSettings();
        } else {
          openSettings();
        }
      }}
    >
      <Popover.Target>
        <div>
          <ActionIcon
            onClick={() => {
              if (settingsOpened) {
                closeSettings();
              } else {
                openSettings();
              }
            }}
          >
            <BsThreeDots />
          </ActionIcon>
        </div>
      </Popover.Target>
      <Popover.Dropdown py={5} px={15}>
        <Flex direction={"column"}>
          {rightSection.title ? (
            <Text variant={TextVariant.BodyEmphasized} pt={"xs"}>
              {rightSection.title}
            </Text>
          ) : undefined}
          {rightSection.options.map((option, index) => {
            return (
              <React.Fragment key={option.label}>
                <UnstyledButton
                  onClick={() => {
                    option.onClick();

                    if (
                      option.closeMenuOnClick != null &&
                      !option.closeMenuOnClick
                    )
                      return;

                    closeSettings();
                  }}
                  ta={"left"}
                  w={"100%"}
                  py={"12px"}
                  disabled={option.loading}
                >
                  <Flex align={"center"} justify={"space-between"} w={"100%"}>
                    <Flex align={"center"} gap={"5px"}>
                      {option.icon != null ? (
                        <ThemeIcon
                          variant={"white"}
                          c={option.color || Color.SecondaryText}
                        >
                          {option.icon}
                        </ThemeIcon>
                      ) : undefined}{" "}
                      <Text
                        span
                        variant={TextVariant.Body}
                        c={option.color || Color.SecondaryText}
                      >
                        {option.label}
                      </Text>
                    </Flex>

                    {option.loading ? <Loader size={"xs"} /> : undefined}
                  </Flex>
                </UnstyledButton>
                {
                  // Don't render divider after last element
                  index !== rightSection.options.length - 1 ? (
                    <Divider />
                  ) : undefined
                }
              </React.Fragment>
            );
          })}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

const buildRightSection = (
  rightSection: AddButton | React.ReactNode,
  isMobile: boolean,
): React.ReactNode => {
  return rightSection && rightSectionIsAddButton(rightSection) ? (
    isMobile ? (
      <ActionIcon
        color="brand"
        variant={"filled"}
        onClick={rightSection.onClick}
        h={"40px"}
        w={"52px"}
        mr={isMobile ? "sm" : undefined}
        data-identifier={rightSection.identifier}
      >
        <RiAddFill size={"1.4rem"} />
      </ActionIcon>
    ) : (
      <Button
        leftSection={<RiAddFill />}
        style={{ flexShrink: 0 }}
        mr={isMobile ? "sm" : undefined}
        onClick={rightSection.onClick}
        data-identifier={rightSection.identifier}
      >
        {rightSection.label}
      </Button>
    )
  ) : (
    rightSection
  );
};
