import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { t } from "@lingui/macro";
import { Flex, Pagination } from "@mantine/core";

import { Route, Routes } from "react-router-dom";
import { useAdminStore } from "./admin.store";
import { ClientDetails } from "./components/ClientDetails/ClientDetails";
import { ClientsTable } from "./components/ClientsTable";
import { FilterRow } from "./components/FilterRow";
import { useClients } from "./hooks/useClients";

export const LimeAdmin = () => {
  const { data, handleChangeSalesPersonForClient, isLoading } = useClients();

  const { setPage, page } = useAdminStore((state) => state);

  return (
    <>
      <PageHeader title={t`Admin`} />

      <Routes>
        <Route
          path="/"
          element={
            <Flex
              gap={10}
              direction={"column"}
              top={0}
              left={0}
              right={0}
              style={{ zIndex: 1 }}
            >
              <FilterRow
                industries={data?.industries || []}
                salesPeople={data?.salesPeople || []}
                pricingTiers={data?.pricingTiers || []}
              ></FilterRow>
              <ClientsTable
                data={data}
                handleChangeSalesPersonForClient={
                  handleChangeSalesPersonForClient
                }
                isLoading={isLoading}
              ></ClientsTable>

              <Pagination
                w={"100%"}
                total={data?.totalPages ?? 10}
                value={page}
                onChange={setPage}
                size={"xs"}
                siblings={1}
                boundaries={1}
              ></Pagination>
            </Flex>
          }
        ></Route>
        <Route
          path="client/:id"
          element={<ClientDetails></ClientDetails>}
        ></Route>
      </Routes>
    </>
  );
};
