export interface RawData {
  [key: string]: string;
}

export interface TransformedData {
  name: string;
  priceBaseCents: number;
  baseDurationMins: number;
  workers: string[];
  color: string;
  tags: string[];
  description?: string;
  timeOffStart?: number;
  timeOffDuration?: number;
}

export const transformData = (data: RawData[]): TransformedData[] => {
  return data.map((item) => {
    return {
      name: item["name"].trim(),
      priceBaseCents: parseFloat(item["price"].replace("€", "").trim()) * 100,
      baseDurationMins: parseInt(item["baseDurationMins"], 10),
      description: item["description"],
      timeOffStart: parseInt(item["timeOffStart"], 10),
      timeOffDuration: parseInt(item["timeOffDuration"], 10),
      workers: item["workers"]
        .split(",")
        .map((w) => w.trim())
        .filter((w) => w),
      color: item["color"].trim(),
      tags: item["tags"]
        .split(",")
        .map((t) => t.trim())
        .filter((t) => t),
    };
  });
};
