import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Box,
  Center,
  Flex,
  HoverCard,
  Pagination,
  Select,
  Table,
} from "@mantine/core";
import dayjs from "dayjs";
import { CgMathMinus, CgMathPlus } from "react-icons/cg";
import { RiEditLine } from "react-icons/ri";
import type { GetAppointmentsLog } from "../../../../../../server/src/types";
import useChangeLogStore from "../store/useChangeLogStore";
import classes from "./AppointmentsLogTable.module.css";
import { hasProperty } from "../../../../../../shared/utils/utils";

type AppointmentColumns =
  | "startTimeLocal"
  | "FK_serviceId"
  | "FK_userId"
  | "FK_locationId"
  | "duration"
  | "price"
  | "isPreScheduled"
  | "timeOffStart"
  | "numberOfPeople"
  | "timeOffDuration"
  | "FK_linkedAppointment"
  | "FK_repeatingAppointment"
  | "isDeleted"
  | "FK_customerId";

const getAppointmentColumnsMap = (key: AppointmentColumns) => {
  const appointmentColumnsMap: Record<AppointmentColumns, string> = {
    duration: t`Trajanje`,
    FK_linkedAppointment: t`Povezan termin`,
    FK_locationId: t`Lokacija`,
    FK_repeatingAppointment: t`Ponavljajoč termin`,
    FK_serviceId: t`Storitev`,
    FK_userId: t`Zaposleni`,
    isPreScheduled: t`Razpisan`,
    numberOfPeople: t`Števil oseb`,
    price: t`Cena`,
    startTimeLocal: t`Začetek termina`,
    timeOffDuration: t`Dolžina pavze`,
    timeOffStart: t`Trajanje pred pavzo`,
    isDeleted: t`Izbrisan`,
    FK_customerId: t`Stranka`,
  };
  return appointmentColumnsMap[key];
};

export const AppointmentsLogTable = ({
  log,
}: {
  log: GetAppointmentsLog["response"]["log"];
}) => {
  useLingui();

  const {
    paginationPageCount,
    paginationPage,
    setPaginationPage,
    logsPerPage,
    setLogsPerPage,
  } = useChangeLogStore((state) => state);

  const actionIcons = {
    update: {
      icon: <RiEditLine />,
      tooltip: t`Spreminjanje termina`,
    },
    insert: {
      icon: <CgMathPlus />,
      tooltip: t`Dodajanje termina`,
    },
    delete: {
      icon: <CgMathMinus />,
      tooltip: t`Brisanje termina`,
    },
  };

  return (
    <Flex direction={"column"}>
      <Table align={"left"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <Trans>Akcija</Trans>
            </Table.Th>
            <Table.Th>
              <Trans>Čas termina</Trans>
            </Table.Th>
            <Table.Th>
              <Trans>Stranka</Trans>
            </Table.Th>
            <Table.Th>
              <Trans>Čas spremembe</Trans>
            </Table.Th>
            <Table.Th>
              <Trans>Spremembo opravil</Trans>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <tbody>
          {log?.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={4}>
                <Center>
                  <div style={{ color: "var(--mantine-colors-gray-500)" }}>
                    <Trans>Ni rezultatov</Trans>
                  </div>
                </Center>
              </Table.Td>
            </Table.Tr>
          )}
          {log?.map((logItem, index) => {
            const action = logItem.action as keyof typeof actionIcons;

            const actionMap = actionIcons[action];

            const changed = logItem.changed as Record<string, string>;

            const oldData = logItem.old_data as Record<string, string>;

            const { timestamp } = logItem;

            return (
              <HoverCard disabled={!logItem.changed} key={index}>
                <HoverCard.Target>
                  <tr key={index}>
                    <Table.Td>
                      <div>{actionMap.icon}</div>
                    </Table.Td>
                    <Table.Td>{logItem.startTime}</Table.Td>
                    <Table.Td>{`${
                      hasProperty(logItem, "name") && logItem.name
                        ? logItem.name
                        : ""
                    }  ${
                      hasProperty(logItem, "lastName") && logItem.lastName
                        ? logItem.lastName
                        : ""
                    }`}</Table.Td>
                    <Table.Td>
                      {dayjs(timestamp).format("DD. MM. HH:mm")}
                    </Table.Td>
                    <Table.Td>{`${
                      hasProperty(logItem, "userName") && logItem.userName
                        ? logItem.userName
                        : t`Stranka`
                    }  ${
                      hasProperty(logItem, "userLastName") &&
                      logItem.userLastName
                        ? logItem.userLastName
                        : ""
                    }`}</Table.Td>
                  </tr>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Flex direction={"column"}>
                    {oldData &&
                      changed &&
                      Object.keys(changed).map((c, i) => (
                        <div key={i}>
                          {`${getAppointmentColumnsMap(
                            c as AppointmentColumns,
                          )} | ${
                            "startTimeLocal" === c
                              ? dayjs(oldData[c]).format("DD. MM. YYYY HH:mm")
                              : oldData[c]
                          } => ${
                            "startTimeLocal" === c
                              ? dayjs(changed[c]).format("DD. MM. YYYY HH:mm")
                              : changed[c]
                          }`}
                        </div>
                      ))}
                  </Flex>
                </HoverCard.Dropdown>
              </HoverCard>
            );
          })}
        </tbody>
      </Table>
      <Box
        style={(_) => ({
          display: "flex",
          flexDirection: "column",
          gap: "1rem",

          "@media (min-width: 768px)": {
            flexDirection: "row",
            justifyContent: "space-between",
          },
        })}
      >
        <Pagination
          total={paginationPageCount}
          color={"dark"}
          withEdges
          value={paginationPage}
          onChange={setPaginationPage}
        />
        <Select
          value={logsPerPage}
          onChange={setLogsPerPage}
          data={[10, 20, 50, 100].map((i) => ({
            label: `${i} ${t`vrstic`}`,
            value: i.toString(),
          }))}
          classNames={{
            root: classes.select_root,
            option: classes.select_option,
          }}
        />
      </Box>
    </Flex>
  );
};
