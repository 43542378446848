import styled from "styled-components";

const ShiftBlock = ({ shift }) => {
  return (
    <>
      <ShiftWrap className={"shift"} shift={shift}></ShiftWrap>
    </>
  );
};

const ShiftWrap = styled.div`
  --inset: 5px;

  width: calc(100% - var(--inset));
  z-index: -1;

  top: calc(${(props) => props.shift.yOffset}px + (var(--inset) / 2));
  height: calc(${(props) => props.shift.blockHeight}px - var(--inset));

  background: #f1fcf1;
  border: 2px dashed #74b587;
  border-radius: 6px;

  left: calc(var(--inset) / 2);

  position: absolute;

  .breaks {
    width: 100%;
    height: 100%;

    position: relative;
  }
`;

const BreakWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
`;

const Break = styled.div`
  overflow: hidden;
  top: ${(props) => props.break.yOffset}px;
  height: ${(props) => props.break.blockHeight}px;

  width: 100%;

  background-image: repeating-linear-gradient(
    159.5deg,
    transparent,
    transparent 8px,
    rgb(243, 244, 247) 8px,
    rgb(243, 244, 247) 18px
  );
  background-size: auto;
  background-color: rgba(255, 255, 255, 1);

  position: absolute;

  display: flex;
  //justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3px;

  //gap: 0.5rem;

  border-width: 2px 2px 2px 6px;
  border-style: solid;
  border-color: ${(props) => props.color};
  border-radius: 12px;
`;

export default ShiftBlock;
