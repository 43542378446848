import { t } from "@lingui/macro";
import {
  ActionIcon,
  Divider,
  Flex,
  Popover,
  SegmentedControl,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import React from "react";
import { RiCalendarTodoLine } from "react-icons/ri";

type DateRangeActionsTypes = {
  handleChangeDateRange: (startDate: Date | null, endDate: Date | null) => void;
  disabledDateRangeOptions?: RangeTypes[];
  additionalActions?: {
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
  }[];
  defaultSelectedOption?: "1d" | "1w" | "1m" | "1y" | "all";
  borderRadius?: number;
  future?: boolean;
};

const rangeTypeValues = ["1d", "1w", "1m", "1y", "all", "custom"] as const;
type RangeTypes = (typeof rangeTypeValues)[number];

export const DateRangeActions = ({
  handleChangeDateRange,
  disabledDateRangeOptions,
  additionalActions,
  defaultSelectedOption,
  borderRadius = 10,
  future = false,
}: DateRangeActionsTypes) => {
  const [selectedOption, setSelectedOption] = React.useState<RangeTypes>(
    defaultSelectedOption || "1d",
  );
  const [customDateRange, setCustomDateRange] = React.useState<
    [Date | null, Date | null]
  >([null, null]);

  const data = [
    { value: "1d", label: t`1D` },
    { value: "1w", label: t`7D` },
    { value: "1m", label: t`1M` },
    { value: "1y", label: t`1L` },
    { value: "all", label: t`VSE` },
    {
      value: "custom",
      label: (
        <Popover>
          <Popover.Target>
            <div style={{ width: "100%", height: "100%" }}>
              <RiCalendarTodoLine fontSize={"1.1rem"} />
            </div>
          </Popover.Target>
          <Popover.Dropdown>
            <DatePicker
              type="range"
              value={customDateRange}
              onChange={(values) => {
                console.log(values);

                if (values[0] === null || values[1] === null) {
                  setCustomDateRange(values);

                  return;
                }

                setCustomDateRange(values);
                setSelectedOption("custom");

                handleChangeDateRange(values[0], values[1]);
              }}
            />
          </Popover.Dropdown>
        </Popover>
      ),
    },
  ];

  // Filter out disabled options
  const filteredData =
    disabledDateRangeOptions && disabledDateRangeOptions.length > 0
      ? data.filter(
          (option) => !disabledDateRangeOptions.includes(option.value),
        )
      : data;

  const onChangeDateRange = (prevValue: RangeTypes, value: RangeTypes) => {
    setSelectedOption(value);

    let startDate;
    let endDate;

    const val = value === "custom" ? prevValue : value;
    switch (val) {
      case "1d":
        startDate = future
          ? dayjs().toDate()
          : dayjs().subtract(1, "day").toDate();
        endDate = future ? dayjs().toDate() : dayjs().endOf("day").toDate();
        break;
      case "1w":
        startDate = future
          ? dayjs().toDate()
          : dayjs().subtract(1, "week").toDate();
        endDate = future
          ? dayjs().add(1, "week").toDate()
          : dayjs().endOf("day").toDate();
        break;
      case "1m":
        startDate = future
          ? dayjs().toDate()
          : dayjs().subtract(1, "month").toDate();
        endDate = future
          ? dayjs().add(1, "month").toDate()
          : dayjs().endOf("day").toDate();
        break;
      case "1y":
        startDate = future
          ? dayjs().toDate()
          : dayjs().subtract(1, "year").toDate();
        endDate = future
          ? dayjs().add(1, "year").toDate()
          : dayjs().endOf("day").toDate();
        break;
      case "all":
        startDate = null;
        endDate = null;
        break;
      default:
        startDate = null;
        endDate = null;
        break;
    }

    if (value === "custom") {
      setCustomDateRange([startDate, endDate]);
      console.log();
      return;
    }

    handleChangeDateRange(startDate, endDate);
  };

  return (
    <Flex
      align={"center"}
      style={{
        background: "#F5F5F5",
        borderRadius: borderRadius,

        position: "relative",
      }}
    >
      <SegmentedControl
        data={filteredData}
        styles={{
          label: {
            color: "#8C8C8C",
            height: "26.09px",
          },
          indicator: {
            boxShadow: "none",
          },
          root: {
            background: "#F5F5F5",
            overflow: "visible",
            flex: 1,
            borderRadius: borderRadius,
          },
        }}
        value={selectedOption}
        onChange={(newRange: string) => {
          if (!rangeTypeValues.includes(newRange as RangeTypes)) {
            return;
          }

          onChangeDateRange(selectedOption, newRange as RangeTypes);
        }}
      />

      {additionalActions != null &&
        additionalActions.map((action) => (
          <React.Fragment key={action.label}>
            <Divider
              orientation="vertical"
              h={"20px"}
              style={{ alignSelf: "center" }}
            />
            <ActionIcon
              key={action.label}
              ml={10}
              onClick={action.onClick}
              mr={"xs"}
              styles={{
                root: {
                  color: "#8C8C8C",
                },
              }}
            >
              {action.icon}
            </ActionIcon>
          </React.Fragment>
        ))}
    </Flex>
  );
};
