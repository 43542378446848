import LimeDrawer from "@/Components/LimeDrawer/LimeDrawer";
import { Color } from "@/types/colors";
import {
  Accordion,
  Button,
  Flex,
  Select,
  Switch,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { RiArrowDownLine, RiListSettingsFill } from "react-icons/ri";
import { GetClientList } from "../../../../../../server/src/types";

import { useForm } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";
import { useAdminStore } from "../admin.store";
import { ShownColumns, columnsArray } from "../types";
import classes from "./FilterRow.module.css";

export const FilterRow = ({
  industries,
  salesPeople,
  pricingTiers,
}: {
  industries: NonNullable<GetClientList["response"]["industries"]>;
  salesPeople: NonNullable<GetClientList["response"]["salesPeople"]>;
  pricingTiers: NonNullable<GetClientList["response"]["pricingTiers"]>;
}) => {
  const {
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    setSearchQuery,
  } = useAdminStore((state) => state);

  const [query, setQuery] = useState<string>("");

  const handleSearch = useDebouncedCallback(() => {
    setSearchQuery(query);
  }, 500);

  useEffect(() => {
    handleSearch();
  }, [query]);

  return (
    <div className={classes.filterRow}>
      <TextInput
        label="Iskanje"
        w={"fit-content"}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      ></TextInput>

      <Flex align={"flex-end"}>
        <Select
          label="Sortiraj po:"
          data={
            [
              { label: "Ime podjetja", value: "businessName" },
              { label: "Datum registracije", value: "dateCreated" },
              {
                label: "Datum zadnje aktivnosti",
                value: "lastAppointmentDate",
              },
              {
                label: "Datum obračuna",
                value: "billingCycleDate",
              },
            ] as {
              label: string;
              value: GetClientList["query"]["orderBy"];
            }[]
          }
          value={orderBy}
          onChange={(e) => {
            setOrderBy(e as GetClientList["query"]["orderBy"]);
          }}
        ></Select>
        <Button
          variant="transparent"
          leftSection={
            <RiArrowDownLine
              style={{
                rotate: orderDirection === "desc" ? "0deg" : "180deg",
                transition: "all 0.3 cubic-bezier(0.4, 0.2, 0.1,0)",
                fontSize: "18px",
              }}
            ></RiArrowDownLine>
          }
          onClick={() =>
            setOrderDirection(orderDirection === "asc" ? "desc" : "asc")
          }
          p={0}
        ></Button>
      </Flex>
      <AdminFilter
        industries={industries}
        salesPeople={salesPeople}
        pricingTiers={pricingTiers}
      ></AdminFilter>
    </div>
  );
};

const AdminFilter = ({
  pricingTiers,
  industries,
  salesPeople,
}: {
  pricingTiers: {
    pricingTierId: string;
    tierName: string;
  }[];
  industries: NonNullable<GetClientList["response"]["industries"]>;
  salesPeople: NonNullable<GetClientList["response"]["salesPeople"]>;
}) => {
  const [opened, setOpened] = useState(false);

  const {
    selectedIndustries,
    selectedSalesPeople,
    selectedTiers,
    showDisabled,
    shownColumns,

    setSelectedIndustries,
    setSelectedSalesPeople,
    setSelectedTiers,
    setShowDisabled,
    setShownColumns,
  } = useAdminStore((state) => state);

  const form = useForm({
    initialValues: {
      selectedTiers,
      showDisabled,
      selectedSalesPeople,
      selectedIndustries,
      shownColumns,
    },
  });

  return (
    <>
      <Button
        style={{
          height: "100%",
          background: Color.TableHeader,
        }}
        justify={"center"}
        px={"sm"}
        onClick={() => setOpened(true)}
        p={6}
      >
        <RiListSettingsFill
          style={{ fontSize: "22px" }}
          color={Color.SecondaryText}
        ></RiListSettingsFill>
      </Button>

      <LimeDrawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Filter"
        buttons={{
          save: (
            <Button
              onClick={() => {
                setOpened(false);

                setSelectedIndustries(form.values.selectedIndustries);
                setSelectedSalesPeople(form.values.selectedSalesPeople);
                setSelectedTiers(form.values.selectedTiers);
                setShowDisabled(form.values.showDisabled);
                setShownColumns(form.values.shownColumns);
              }}
            >
              Shrani
            </Button>
          ),
          cancel: (
            <Button
              variant="outline"
              onClick={() => {
                setOpened(false);
                form.reset();
              }}
            >
              Prekliči
            </Button>
          ),
        }}
      >
        <Switch
          checked={showDisabled}
          label={"Prikaži onemogočene"}
          {...form.getInputProps("showDisabled")}
        ></Switch>

        <Accordion>
          <Accordion.Item value="tier">
            <Accordion.Control>Paket</Accordion.Control>
            <Accordion.Panel>
              <Flex direction={"column"}>
                {pricingTiers.map((tier) => (
                  <UnstyledButton
                    key={tier.pricingTierId}
                    className={`${classes.option} ${
                      form.values.selectedTiers.includes(tier.pricingTierId)
                        ? classes.active
                        : ""
                    }`}
                    onClick={() => {
                      const newSelectedTiers =
                        form.values.selectedTiers.includes(tier.pricingTierId)
                          ? form.values.selectedTiers.filter(
                              (id) => id !== tier.pricingTierId,
                            )
                          : [...form.values.selectedTiers, tier.pricingTierId];
                      form.setFieldValue("selectedTiers", newSelectedTiers);
                    }}
                  >
                    {tier.tierName}
                  </UnstyledButton>
                ))}
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="salesPerson">
            <Accordion.Control>Sales guy</Accordion.Control>
            <Accordion.Panel>
              {salesPeople.map((salesPerson) => (
                <UnstyledButton
                  key={salesPerson}
                  className={`${classes.option} ${
                    form.values.selectedSalesPeople.includes(salesPerson)
                      ? classes.active
                      : ""
                  }`}
                  onClick={() => {
                    const newSalesPeople =
                      form.values.selectedSalesPeople.includes(salesPerson)
                        ? form.values.selectedSalesPeople.filter(
                            (id) => id !== salesPerson,
                          )
                        : [...form.values.selectedSalesPeople, salesPerson];

                    form.setFieldValue("selectedSalesPeople", newSalesPeople);
                  }}
                >
                  {salesPerson}
                </UnstyledButton>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="industry">
            <Accordion.Control>Industrija</Accordion.Control>
            <Accordion.Panel>
              {industries.map((industry) => (
                <UnstyledButton
                  key={industry}
                  className={`${classes.option} ${
                    form.values.selectedIndustries.includes(industry)
                      ? classes.active
                      : ""
                  }`}
                  onClick={() => {
                    const newIndustries =
                      form.values.selectedIndustries.includes(industry)
                        ? form.values.selectedIndustries.filter(
                            (id) => id !== industry,
                          )
                        : [...form.values.selectedIndustries, industry];
                    form.setFieldValue("selectedIndustries", newIndustries);
                  }}
                >
                  {industry}
                </UnstyledButton>
              ))}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="shownColumns">
            <Accordion.Control>Prikazani stolpci</Accordion.Control>
            <Accordion.Panel>
              {columnsArray.map((option: ShownColumns[0]) => (
                <UnstyledButton
                  key={option}
                  className={`${classes.option} ${
                    form.values.shownColumns.includes(option)
                      ? classes.active
                      : ""
                  }`}
                  onClick={() => {
                    const newShownColumns = form.values.shownColumns.includes(
                      option,
                    )
                      ? form.values.shownColumns.filter((id) => id !== option)
                      : [...form.values.shownColumns, option];
                    form.setFieldValue("shownColumns", newShownColumns);
                  }}
                >
                  {option}
                </UnstyledButton>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </LimeDrawer>
    </>
  );
};

// export type FilterFormValues = {
//   selectedTiers: NonNullable<GetClientList["query"]["tiers"]>;
//   showDisabled: boolean;
//   selectedSalesPeople: GetClientList["query"]["selectedSalesPeople"];
//   selectedIndustries: GetClientList["query"]["selectedIndustries"];
//   shownColumns: ShownColumns;
// };
