import { api } from "@/lib/api-client";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { Trans, t } from "@lingui/macro";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { AxiosError } from "axios";
import { AuthFormWrapper } from "../AuthFormWrapper";

export const Register = () => {
  const { mutateAsync: register, isPending, error } = api.auth.useRegister();

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);

  const form = useForm({
    initialValues: {
      email: "",
      language: "sl",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim()) ? null : t`Neveljaven e-poštni naslov`,
    },
  });

  const onSubmitForm = async (values: typeof form.values) => {
    const registerData = {
      email: values.email.trim(),
      language: "sl",
    };

    registerData.language = preferredLanguage.userPreferredLanguage;

    const response = await register(registerData);

    localStorage.setItem("usr_token", response.token);
    window.location.href = "/";
  };

  return (
    <AuthFormWrapper isLogin={false}>
      <form
        style={{ display: "contents" }}
        onSubmit={form.onSubmit(onSubmitForm)}
        noValidate
      >
        <TextInput
          label={t`E-mail naslov`}
          data-identifier={"email-input"}
          variant="filled"
          size="md"
          w={"100%"}
          {...form.getInputProps("email")}
          autoComplete="email"
          name="email"
          error={
            form.errors.email ||
            (error instanceof AxiosError && error.response?.data?.error)
          }
        />

        <Button
          mt={"lg"}
          fullWidth
          type="submit"
          loading={isPending}
          data-identifier="continue-button"
        >
          <Trans>Nadaljuj</Trans>
        </Button>
      </form>
    </AuthFormWrapper>
  );
};
