import LimePhoneInputField from "@/Components/LimePhoneInputField";
import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { getLegalPages } from "@/utils";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Flex,
  Modal,
  Text,
  TextInput,
  Card,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery, useDebouncedValue } from "@mantine/hooks";
import { useState, useEffect } from "react";
import { BiHide, BiShow, BiCheckCircle } from "react-icons/bi";
import { RiAlertLine } from "react-icons/ri";
import { type Value, isValidPhoneNumber } from "react-phone-number-input";

const IsPasswordLengthValid = (value: string) => value.length >= 8;
const IsPasswordNumberValid = (value: string) => /\d/.test(value);
const IsPasswordSpecialCharacterValid = (value: string) =>
  /[!@#$%^&*]/.test(value);
const IsPasswordBigCharacterValid = (value: string) => /[A-Z]/.test(value);
const IsPasswordSmallCharacterValid = (value: string) => /[a-z]/.test(value);

export const CalendarOnboardingPopup = ({
  handleSkipOnboarding,
}: {
  handleSkipOnboarding: () => void;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [hasContinued, setHasContinued] = useState(false);
  const [showPromoCodeField, setShowPromoCodeField] = useState(false);

  const form = useForm({
    initialValues: {
      // first page
      name: "",
      lastName: "",
      gsm: "" as Value,
      promoCode: "",

      // second page
      password: "",
      confirmPassword: "",
      terms: false,
    },
    validate: {
      name: (value) => {
        if (hasContinued) return null;
        if (!value) return t`Vnesite ime`;
        return value.length > 2 ? null : t`Ime je prekratko`;
      },
      lastName: (value) => {
        if (hasContinued) return null;
        if (!value) return t`Vnesite priimek`;
        return value.length > 2 ? null : t`Priimek je prekratek`;
      },
      gsm: (value) => {
        if (hasContinued) return null;
        if (!value) return null;
        return isValidPhoneNumber(value)
          ? null
          : t`Neveljavna telefonska številka`;
      },
      password: (value) => {
        if (!hasContinued) return null;
        if (!value) return t`Vnesite geslo`;
        if (!IsPasswordLengthValid(value)) return t`Geslo je prekratko`;
        if (!IsPasswordNumberValid(value))
          return t`Geslo mora vsebovati vsaj eno številko`;
        if (!IsPasswordSpecialCharacterValid(value))
          return t`Geslo mora vsebovati vsaj en poseben znak`;
        if (!IsPasswordBigCharacterValid(value))
          return t`Geslo mora vsebovati vsaj eno veliko črko`;
        if (!IsPasswordSmallCharacterValid(value))
          return t`Geslo mora vsebovati vsaj eno malo črko`;
        return null;
      },
      confirmPassword: (value, values) => {
        if (!hasContinued) return null;
        if (!value) return t`Ponovno vnesite geslo`;
        return value === values.password ? null : t`Gesli se ne ujemata`;
      },
      terms: (value) => {
        if (!hasContinued) return null;
        if (!value) return t`Za nadaljevanje morate sprejeti pogoje poslovanja`;
      },
    },
  });

  const { mutateAsync: finishOnboarding, isPending } =
    api.auth.useFinishOnboarding();

  const {
    mutateAsync: checkPromoCode,
    isPending: isCheckingPromocode,
    data: checkPromoCodeData,
  } = api.promoCodes.useCheckPromocode();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword((prev) => !prev);
  };

  const onSubmitForm = async (values: typeof form.values) => {
    if (!hasContinued) {
      setHasContinued(true);
      return;
    }
    const response = await finishOnboarding(values);
    if (response.message === "OK") {
      window.location.href = "/";
    }
  };

  const [debouncedPromoCode] = useDebouncedValue(form.values.promoCode, 500);

  useEffect(() => {
    if (debouncedPromoCode) {
      checkPromoCode({ code: debouncedPromoCode });
    }
  }, [debouncedPromoCode]);

  const isMobile = useMediaQuery("(max-width: 768px)") ?? false;

  const { privacyPolicyUrl, termsAndConditionsUrl } = getLegalPages({});

  return (
    <Wrapper isMobile={isMobile}>
      <Flex justify={"space-between"} pt={"xs"}>
        <Text variant={TextVariant.Heading}>
          {hasContinued ? t`Vnesi geslo` : t`Osnovni podatki`}
        </Text>
        <Text>{hasContinued ? 2 : 1}/2</Text>
      </Flex>
      <Divider my={"lg"} />
      <form onSubmit={form.onSubmit(onSubmitForm)} noValidate>
        <Flex direction={"column"} gap={"md"}>
          {!hasContinued ? (
            <>
              <TextInput
                label={t`Ime`}
                data-identifier="name-input"
                variant="filled"
                {...form.getInputProps("name")}
                required
                error={form.errors.name && form.errors.name}
              />
              <TextInput
                label={t`Priimek`}
                data-identifier="lastName-input"
                variant="filled"
                {...form.getInputProps("lastName")}
                required
              />
              <LimePhoneInputField
                {...form.getInputProps("gsm")}
                onChange={(e: Value) => {
                  form.setFieldValue("gsm", e);
                }}
                form
                label={t`Telefonska številka`}
                defaultCountry={"SI"}
                autocomplete="do-not-autofill"
                showError={false}
                disabled={false}
                background="transparent"
                border="none"
                color="black"
                disablePointerEvents={false}
              />
              <Checkbox
                label={t`Imate promocijsko kodo?`}
                checked={showPromoCodeField}
                onChange={(e) => setShowPromoCodeField(e.currentTarget.checked)}
              />
              {showPromoCodeField && (
                <>
                  <TextInput
                    label={t`Promocijska koda`}
                    variant="filled"
                    {...form.getInputProps("promoCode")}
                    style={{ flexGrow: 1 }}
                    error={
                      checkPromoCodeData?.valid === false
                        ? t`Promocijska koda ni veljavna ali pa je potekla`
                        : undefined
                    }
                  />
                  {checkPromoCodeData?.valid === true &&
                    checkPromoCodeData.promoCode && (
                      <Card withBorder radius="md" p={"md"}>
                        <Text c="#68a379">
                          <Flex
                            align="center"
                            gap={"xs"}
                            justify={"space-between"}
                          >
                            <Trans>Promocijska koda je veljavna</Trans>{" "}
                            <BiCheckCircle size={"1.5rem"} color="#68a379" />
                          </Flex>
                        </Text>
                      </Card>
                    )}
                </>
              )}
            </>
          ) : (
            <>
              <TextInput
                label={t`Geslo`}
                variant="filled"
                {...form.getInputProps("password")}
                required
                type={showPassword ? "text" : "password"}
                name="new-password"
                data-identifier="password-input"
                autoComplete="new-password"
                rightSection={
                  !showPassword ? (
                    <BiShow
                      color="#c9cccf"
                      size={23}
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <BiHide
                      color="#c9cccf"
                      size={23}
                      onClick={togglePasswordVisibility}
                    />
                  )
                }
              />
              <TextInput
                label={t`Ponovi geslo`}
                data-identifier="confirm-password-input"
                variant="filled"
                {...form.getInputProps("confirmPassword")}
                required
                type={showRepeatPassword ? "text" : "password"}
                name="new-password"
                autoComplete="new-password"
                rightSection={
                  !showRepeatPassword ? (
                    <BiShow
                      color="#c9cccf"
                      size={23}
                      onClick={toggleRepeatPasswordVisibility}
                    />
                  ) : (
                    <BiHide
                      color="#c9cccf"
                      size={23}
                      onClick={toggleRepeatPasswordVisibility}
                    />
                  )
                }
              />
              <PasswordRequirements
                smallCharacters={IsPasswordSmallCharacterValid(
                  form.values.password,
                )}
                bigCharacters={IsPasswordBigCharacterValid(
                  form.values.password,
                )}
                specialCharacters={IsPasswordSpecialCharacterValid(
                  form.values.password,
                )}
                numbers={IsPasswordNumberValid(form.values.password)}
                length={IsPasswordLengthValid(form.values.password)}
              />
              <Checkbox
                data-identifier="tac-checkbox"
                label={
                  <Text variant={TextVariant.Caption}>
                    <Trans>Strinjam se s</Trans>{" "}
                    {
                      <Text
                        c={"green"}
                        inline
                        span
                        component="a"
                        href={termsAndConditionsUrl}
                      >
                        <Trans>splošnimi pogoji poslovanja</Trans>
                      </Text>
                    }{" "}
                    <Trans>in</Trans>{" "}
                    {
                      <Text
                        c={"green"}
                        inline
                        span
                        component="a"
                        href={privacyPolicyUrl}
                      >
                        <Trans>politiko zasebnosti</Trans>
                      </Text>
                    }
                    .
                  </Text>
                }
                {...form.getInputProps("terms")}
                radius={"xl"}
              />
            </>
          )}
        </Flex>

        <Divider my={"lg"} />
        <Flex justify={"flex-end"}>
          <Button variant="white" onClick={() => handleSkipOnboarding()}>
            <Trans>Preskoči</Trans>
          </Button>
          {hasContinued && (
            <Button variant="white" onClick={() => setHasContinued(false)}>
              <Trans>Nazaj</Trans>
            </Button>
          )}
          {hasContinued ? (
            <Button
              data-identifier="continue-onboarding-button"
              className="onboard-finish-btn"
              type={"submit"}
              loading={isPending}
            >
              <Trans>Nadaljuj</Trans>
            </Button>
          ) : (
            <Button
             data-identifier="continue-onboarding-button"
              className="onboard-continue-btn"
              type={"submit"}
              loading={isPending}
            >
              <Trans>Nadaljuj</Trans>
            </Button>
          )}
        </Flex>
      </form>
    </Wrapper>
  );
};

const Wrapper = ({
  isMobile,
  children,
}: {
  isMobile: boolean;
  children: React.ReactNode;
}) =>
  !isMobile ? (
    <Modal
      opened={true}
      onClose={() => {}}
      size="450px"
      withCloseButton={false}
      radius={"16px"}
      centered={!isMobile}
    >
      {children}
    </Modal>
  ) : (
    <Drawer
      opened={true}
      onClose={() => {}}
      size="490px"
      position="bottom"
      withCloseButton={false}
      styles={{
        content: {
          borderTopLeftRadius: "16px !important",
          borderTopRightRadius: "16px !important",
        },
      }}
    >
      {children}
    </Drawer>
  );

const PasswordRequirements = ({
  smallCharacters,
  bigCharacters,
  specialCharacters,
  numbers,
  length,
}: {
  smallCharacters: boolean;
  bigCharacters: boolean;
  specialCharacters: boolean;
  numbers: boolean;
  length: boolean;
}) => {
  return (
    <Flex gap={"md"} align={"flex-start"}>
      <RiAlertLine
        color={
          smallCharacters &&
          bigCharacters &&
          specialCharacters &&
          numbers &&
          length
            ? Color.Success
            : Color.Error
        }
        size={"1.5rem"}
        style={{
          flexShrink: 0,
        }}
      />
      <Text variant={TextVariant.Caption}>
        <Trans>Geslo mora vsebovati</Trans>{" "}
        {
          <PasswordRequirementText
            text={t`eno malo začetnico`}
            valid={smallCharacters}
          />
        }
        ,{" "}
        {
          <PasswordRequirementText
            text={t`veliko začetnico`}
            valid={bigCharacters}
          />
        }
        ,{" "}
        {
          <PasswordRequirementText
            text={t`poseben znak`}
            valid={specialCharacters}
          />
        }{" "}
        in {<PasswordRequirementText text={t`številko`} valid={numbers} />}.
        Dolgo mora biti{" "}
        {<PasswordRequirementText text={t`vsaj 8 znakov`} valid={length} />}.
      </Text>
    </Flex>
  );
};

const PasswordRequirementText = ({
  text,
  valid,
}: {
  text: string;
  valid: boolean;
}) => {
  return (
    <Text
      variant={TextVariant.CaptionEmphasized}
      inline
      span
      c={valid ? Color.Success : Color.Error}
    >
      {text}
    </Text>
  );
};
