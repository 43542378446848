import axios from "axios";

/*
axios.defaults.headers.common["x-access-token"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiemlnYSIsInVzZXJUeXBlIjoxLCJjbGllbnRJZCI6IlNlbnRpbmEiLCJpYXQiOjE2MzgzNTQ2MjAsImV4cCI6MTYzODM3MjYyMH0.ipJm6rUHkCASPSuw7RVj7nezU57-h6RI_MAEsz6_4T0";
*/

const prefix = import.meta.env.VITE_BE_HOST || "http://localhost:8080";

const apiLogin = (data) => {
  return axios.post(`${prefix}/user/login`, data);
};

const apiForgotPassword = (data) => {
  return axios.post(`${prefix}/user/forgotPassword`, data);
};

const apiResetPassword = (data) => {
  return axios.post(`${prefix}/user/resetPassword`, data);
};

const getInfoFromToken = (token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };

  return axios.post(`${prefix}/verify/client`, {}, config);
};

const apiAddLocation = (data, token, assignLocationToUsers) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.post(
    `${prefix}/params/addLocation`,
    { ...data, assignLocationToUsers },
    config,
  );
};

const apiGetLocations = (token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };

  return axios.get(`${prefix}/params/client/locations`, config);
};

const apiAddUser = (data, token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.post(`${prefix}/user/registerUser`, data, config);
};

const apiGetUsers = (token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.get(`${prefix}/user/getUsers`, config);
};

const apiUpdateUser = (data, token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.post(`${prefix}/user/updateUser`, data, config);
};

const apiDeleteUser = (data, token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.post(`${prefix}/user/deleteUser`, data, config);
};

const apiUpdateLocation = (data, token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.post(`${prefix}/params/updateLocation`, { ...data }, config);
};

const apiGetServices = (token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.get(`${prefix}/params/getServices`, config);
};

const apiUpdateCustomer = (data, token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.post(`${prefix}/appointment/updateCustomer`, data, config);
};

export const apiGetUserPermissions = () => {
  const token = localStorage.getItem("usr_token");
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.get(`${prefix}/user/user_permissions`, config);
};

export const apiGetPreferredLanguage = (token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
  return axios.get(`${prefix}/user/preferred_language`, config);
};
export {
  apiUpdateCustomer,
  apiLogin,
  apiAddLocation,
  apiGetLocations,
  apiAddUser,
  apiGetUsers,
  apiUpdateUser,
  apiDeleteUser,
  apiUpdateLocation,
  apiGetServices,
  apiResetPassword,
  apiForgotPassword,
  getInfoFromToken,
};
