import { Trans } from "@lingui/macro";
import { ActionIcon, Button, Checkbox, Flex } from "@mantine/core";
import { BsPersonVcard } from "react-icons/bs";

const CustomerList = ({
  customers,
  setSelectedUserAppointments,
  selectedUserAppointments,
  handleUserAppointmentSelect,
  handleRemoveCustomerFromPreScheduledAppointment,
  openCustomerCard,
  IS_GLOWUP_USER = false,
}) => {
  return (
    <div className="flex-1">
      <div
        className={
          "mb-0 flex min-h-16 items-center justify-between border-b border-[#eaebed] py-2"
        }
      >
        <span>Vse dodane osebe</span>
        {customers.length > 0 && (
          <Button
            variant={"light"}
            onClick={() => {
              if (selectedUserAppointments.length === customers.length) {
                setSelectedUserAppointments([]);
              } else {
                setSelectedUserAppointments(
                  customers.map((c) => c.userAppointmentId),
                );
              }
            }}
          >
            <Trans>Izberi vse</Trans>
          </Button>
        )}
      </div>
      <div>
        {customers.map((customer) => {
          const isSelected = selectedUserAppointments?.includes(
            customer.userAppointmentId,
          );

          return (
            <Checkbox
              className="select-none border-b border-[#eaebed] py-5"
              styles={{
                body: {
                  alignItems: "center",
                },
              }}
              key={customer.userAppointmentId}
              isSelected={isSelected}
              label={
                <div className="flex w-full items-center justify-between">
                  <p>
                    {customer.name} {customer.lastName}
                  </p>
                  {!IS_GLOWUP_USER && (
                    <ActionIcon
                      onClick={() => {
                        if (openCustomerCard) {
                          openCustomerCard(customer.customerId);
                        }
                      }}
                    >
                      <BsPersonVcard />
                    </ActionIcon>
                  )}
                </div>
              }
              checked={isSelected}
              radius={"sm"}
              onChange={() => {
                handleUserAppointmentSelect(customer.userAppointmentId);
              }}
            />
          );
        })}
      </div>
      <Flex gap={"1rem"} style={{ marginTop: "1rem" }}>
        <Button
          disabled={selectedUserAppointments.length === 0}
          variant={"light"}
          color={"red"}
          onClick={() =>
            handleRemoveCustomerFromPreScheduledAppointment(
              selectedUserAppointments,
            )
          }
        >
          <Trans>Izbriši</Trans> ({selectedUserAppointments.length})
        </Button>
      </Flex>
    </div>
  );
};

export default CustomerList;
