import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Button, Flex } from "@mantine/core";
import { useState } from "react";
import { RiArrowRightSLine, RiCheckboxCircleFill } from "react-icons/ri";
import { CalendarSubPillar } from "./PermissionsViewComponents/CalendarSubPillar";
import { Section } from "./PermissionsViewComponents/Section";
import { Toggles } from "./PermissionsViewComponents/Toggles";
import { allPermissionPages, isPageVisited } from "./Users";
import { useUserStore } from "./userUserStore";

export const ManagingPermissionsView = ({
  user,
  isMobile,
  isBackButton,
  setIsBackButton,
  setIsManagingPermissions,
  permissions,
  setPermissions,
  visitedPermissionPages,
  setVisitedPermissionPages,
  disabled,
}) => {
  const { users } = useUserStore((state) => state);

  useLingui();

  const choices = [
    {
      id: "settings",
      label: t`Nastavitve`,
    },
    {
      id: "calendar",
      label: t`Koledar`,
      subChoices: [
        {
          id: "termini",
          label: t`Termini`,
          toggles: [
            {
              id: "manage_appointments",
              label: t`Uporabnik lahko dodaja, ureja in briše termine na koledarju.`,
              isBlock: true,
            },
            {
              id: "view_customer_card_on_calendar",
              label: t`Uporabnik lahko vidi kartico stranke na terminu.`,
              isBlock: true,
            },
            {
              id: "restrict_calendar_filter",
              label: t`Uporabnik nima dostopa do filtra koledarja`,
              isBlock: true,
            },
            {
              id: "restrict_appointment_services",
              label: t`Omeji storitve`,
              isBlock: true,
              description: t`Termini se lahko dodajo samo tistim izvajalcem, ki izvajajo izbrano storitev`,
            },
            {
              id: "restrict_appointment_to_shifts",
              label: t`Omeji na delovni čas`,
              isBlock: true,
              description: t`Termini se lahko dodajo samo znotraj delovnega časa izbranega izvajalca`,
            },
            {
              id: "restrict_appointment_overlaps",
              label: t`Onemogoči prekrivanje terminov`,
              isBlock: true,
              description: t`Prekrivanje terminov v koledarju je onemogočeno`,
            },
            {
              id: "restrict_custom_price",
              label: t`Onemogoči ceno po meri za termin`,
              isBlock: true,
              description: t`Cena po meri je onemogočena`,
            },
            {
              id: "restrict_custom_duration",
              label: t`Onemogoči trajanje po meri za termin`,
              isBlock: true,
              description: t`Trajanje termina po meri je onemogočeno`,
            },
            {
              id: "show_appointment_warnings",
              label: t`Prikaži opozorila ob ustvarjanju termina`,
              isBlock: true,
              description: t`Prikaz opozoril na koledarju pri ustvarjanju termina`,
            },
            // {
            //   id: "hide_past_appointments_from_calendar",
            //   label: t`Skrij pretekle termine iz koledarja`,
            //   isBlock: true,
            //   description: t`Skrij pretekle termine v koledarju`,
            // },
          ],
        },

        {
          id: "schedule",
          label: t`Urniki`,
          toggles: [
            {
              id: "view_availability",
              label: t`Uporabnik lahko dodaja, ureja in briše urnike.`,
              isBlock: true,
            },
          ],
        },
        {
          id: "storitve",
          label: t`Storitve`,
          toggles: [
            {
              id: "calendar_services",
              label: t`Vidljivost vseh storitev`,
              description: t`Uporabniku so prikazane vse storitve.`,
              isBlock: true,
            },
          ],
          serviceToggles: [
            {
              sectionName: t`Vidljivost posameznih storitev`,
              toggles: permissions.services,
            },
          ],
        },
        {
          id: "lokacija",
          label: t`Lokacija`,
          toggles: [
            {
              id: "calendar_locations",
              label: t`Vidljivost vseh lokacij`,
              description: t`Uporabniku so prikazane vse lokacije na koledarju.`,
              isBlock: true,
            },
          ],
          locationToggles: [
            {
              sectionName: t`Vidljivost posameznih lokacij`,
              toggles: permissions.locations,
            },
          ],
        },
        {
          id: "users",
          label: <Trans>Zaposleni</Trans>,
          toggles: [
            {
              id: "calendar_users",
              label: <Trans>Vidljivost vseh zaposlenih</Trans>,
              description: <Trans>Uporabniku so prikazani vsi zaposleni</Trans>,

              isBlock: true,
            },
          ],
          userToggles: [
            {
              sectionName: <Trans>Vidljivost posameznih zaposlenih</Trans>,

              toggles: permissions.users,
              users,
            },
          ],
        },
        {
          id: "resources",
          label: t`Sredstva`,
          toggles: [
            {
              id: "calendar_resources",
              label: t`Vidljivost vseh sredstev`,
              description: t`Uporabniku so prikazana vse sredstva`,

              isBlock: true,
            },
          ],
          resourceToggles: [
            {
              sectionName: t`Vidljivost posameznih sredstev`,

              toggles: permissions.resources,
              users,
            },
          ],
        },
      ],
    },
    {
      id: "analytics",
      label: t`Analitika in pregled strank`,
    },
  ];

  const [selectedChoice, setSelectedChoice] = useState(
    isMobile ? null : choices[0]
  );
  const [selectedSubChoice, setSelectedSubChoice] = useState(null);

  const setServicePermissions = (serviceId, permissionId, value) => {
    setPermissions((prev) => {
      const newServices = prev.services.map((service) => {
        if (service.id === serviceId) {
          return {
            ...service,
            permissions: service.permissions.map((permission) => {
              if (permission.id === permissionId) {
                return {
                  ...permission,
                  value,
                };
              }
              return permission;
            }),
          };
        }
        return service;
      });
      return {
        ...prev,
        services: newServices,
      };
    });
  };

  const setUserPermissions = (userId, permissionId, value) => {
    setPermissions((prev) => {
      const newUsers = prev.users.map((user) => {
        if (user.userId === userId) {
          return {
            ...user,

            permissions: user.permissions.map((permission) => {
              if (permission.id === permissionId) {
                return {
                  ...permission,
                  value,
                };
              }
              return permission;
            }),
          };
        }
        return user;
      });
      return {
        ...prev,
        users: newUsers,
      };
    });
  };

  const setResourcePermissions = (resourceId, permissionId, value) => {
    setPermissions((prev) => {
      const newResources = prev.resources.map((resource) => {
        if (resource.resourceId === resourceId) {
          return {
            ...resource,

            permissions: resource.permissions.map((permission) => {
              if (permission.id === permissionId) {
                return {
                  ...permission,
                  value,
                };
              }
              return permission;
            }),
          };
        }
        return resource;
      });
      return {
        ...prev,
        resources: newResources,
      };
    });
  };

  const setLocationPermissions = (locationId, permissionId, value) => {
    setPermissions((prev) => {
      const newLocations = prev.locations.map((location) => {
        if (location.id === locationId) {
          return {
            ...location,

            permissions: location.permissions.map((permission) => {
              if (permission.id === permissionId) {
                return {
                  ...permission,
                  value,
                };
              }
              return permission;
            }),
          };
        }
        return location;
      });
      return {
        ...prev,

        locations: newLocations,
      };
    });
  };

  const settingsToggles = [
    {
      id: "view_locations_page",
      label: t`Lokacije`,
      description: t`Uporabnik lahko dodaja, ureja in briše lokacije.`,
      isBlock: true,
    },
    {
      id: "view_services_page",
      label: t`Storitve`,
      description: t`Uporabnik lahko dodaja, ureja in briše storitve.`,
      isBlock: true,
    },
    {
      id: "view_resources_page",
      label: t`Sredstva`,
      description: t`Uporabnik lahko dodaja, ureja in briše sredstva.`,
      isBlock: true,
    },
    {
      id: "view_users_page",
      label: t`Uporabniki`,
      description: t`Uporabnik lahko dodaja, ureja in briše uporabnike.`,
      isBlock: true,
    },
    {
      id: "view_settings_page",
      label: t`Splošne nastavitve`,
      description: t`Uporabnik lahko vidi splošne nastavitve.`,
      isBlock: true,
    },
    {
      id: "view_notifications_page",
      label: t`Obvestila`,
      description: t`Uporabnik lahko vidi obvestila.`,
      isBlock: true,
    },
    {
      id: "view_online_payments_page",
      label: t`Spletna plačila`,
      description: t`Uporabnik lahko vidi spletna plačila.`,
      isBlock: true,
    },
    {
      id: "view_integrations_page",
      label: t`Integracije`,
      description: t`Uporabnik lahko vidi integracije.`,
      isBlock: true,
    },
  ];

  const analyticsToggles = [
    {
      sectionName: t`Analitika`,
      toggles: [
        {
          id: "view_analytics",
          label: t`Uporabniku je vidna analitika.`,
        },
        {
          id: "analytics_finance",
          label: t`Uporabniku je omogočen vpogled v finance.`,
        },
      ],
    },
    {
      sectionName: t`Pregled strank`,
      toggles: [
        {
          id: "view_customer_list",
          label: t`Uporabniku je viden pregled strank.`,
        },
      ],
    },
    {
      sectionName: t`Zgodovina sprememb`,
      toggles: [
        {
          id: "view_change_log_page",
          label: t`Uporabniku je viden zavihek zgodovina sprememb.`,
        },
      ],
    },
  ];

  const setHasVisitedPage = (permissionId) => {
    if (visitedPermissionPages.includes(permissionId)) return;

    setVisitedPermissionPages((prev) => {
      return [...prev, permissionId];
    });
  };

  const goToNextChoice = () => {
    if (allPermissionPages.length === visitedPermissionPages.length) {
      setIsManagingPermissions(false);
      return;
    }

    if (selectedChoice == null) {
      setSelectedChoice(choices[0]);
      setHasVisitedPage(choices[0].id);
      return;
    }

    if (selectedChoice?.subChoices) {
      if (selectedSubChoice == null) {
        setSelectedSubChoice(selectedChoice.subChoices[0]);
        setHasVisitedPage(selectedChoice.subChoices[0].id);
        return;
      }

      // get next choice in subChoices in line
      let foundNextChoice = false;
      Object.keys(selectedChoice.subChoices).forEach((key) => {
        if (selectedChoice.subChoices[key].id === selectedSubChoice.id) {
          const nextChoice =
            selectedChoice.subChoices[Number.parseInt(key) + 1];
          if (nextChoice) {
            setSelectedSubChoice(nextChoice);
            setHasVisitedPage(nextChoice.id);
            foundNextChoice = true;
          }
        }
      });

      if (foundNextChoice) {
        return;
      }
    }

    // get next choice in choices in line
    Object.keys(choices).forEach((key) => {
      if (choices[key].id === selectedChoice.id) {
        const nextChoice = choices[Number.parseInt(key) + 1];
        if (nextChoice) {
          setSelectedChoice(nextChoice);
          setHasVisitedPage(nextChoice.id);
          setSelectedSubChoice(null);
        }
        return;
      }
    });
  };

  const togglePermission = (id) => {
    setPermissions((prev) => {
      if (id === "analytics_finance" && !prev[id]) {
        return {
          ...prev,
          [id]: !prev[id],
          view_analytics: true,
        };
      }
      if (id === "view_analytics" && prev[id]) {
        return {
          ...prev,
          [id]: !prev[id],
          analytics_finance: false,
        };
      }
      return {
        ...prev,
        [id]: !prev[id],
      };
    });
  };

  const getContent = () => {
    if (!selectedChoice) return <></>;

    if (selectedChoice.id === "settings") {
      return (
        <Toggles
          disabled={disabled}
          isMobile={isMobile}
          permissions={permissions}
          togglePermission={togglePermission}
          toggles={settingsToggles}
        />
      );
    }
    if (selectedChoice.id === "calendar") {
      return (
        <CalendarSubPillar
          disabled={disabled}
          user={user}
          isMobile={isMobile}
          permissions={permissions}
          togglePermission={togglePermission}
          setLocationPermissions={setLocationPermissions}
          setServicePermissions={setServicePermissions}
          setUserPermissions={setUserPermissions}
          setResourcePermissions={setResourcePermissions}
          calendarSubPillarChoice={selectedSubChoice}
          setCalendarSubPillarChoice={(val) => {
            setSelectedSubChoice(val);
            setIsBackButton(true);
          }}
          visitedPermissionPages={visitedPermissionPages}
          setVisitedPermissionPages={setVisitedPermissionPages}
          choices={selectedChoice.subChoices}
        />
      );
    }
    if (selectedChoice.id === "analytics") {
      return (
        <div style={{ flex: 1 }}>
          {analyticsToggles.map((section, index) => {
            return (
              <Section
                isMobile={isMobile}
                title={section.sectionName}
                bordered={index !== analyticsToggles.length - 1}
                key={section.sectionName}
              >
                <Toggles
                  disabled={disabled}
                  permissions={permissions}
                  togglePermission={togglePermission}
                  toggles={section.toggles}
                />
              </Section>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-between text-left">
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {(!isMobile || (isMobile && !selectedChoice)) && (
          <Box
            miw={"200px"}
            w={isMobile ? "100%" : "33%"}
            style={{
              userSelect: "none",
              borderRight: isMobile ? "none" : "1px solid #eaebed",
            }}
          >
            {choices.map((choice, _index) => {
              const isSelected =
                selectedChoice && selectedChoice.id === choice.id;

              return (
                <Flex
                  key={choice.id}
                  h={isMobile ? "60px" : "80px"}
                  align={"center"}
                  justify={"space-between"}
                  p={isMobile ? 0 : "0 20px"}
                  bg={isSelected ? "#F6F8F9" : "transparent"}
                  style={{
                    borderBottom: "1px solid #eaebed",
                    cursor: "pointer",
                    flexShrink: 0,
                  }}
                  fw={"bold"}
                  onClick={() => {
                    setSelectedChoice(choice);
                    setIsBackButton(true);
                    if (!visitedPermissionPages.includes(choice.id)) {
                      setVisitedPermissionPages((prev) => [...prev, choice.id]);
                    }
                  }}
                  isSelected={isSelected}
                >
                  {choice.label}
                  {user == null &&
                  isPageVisited(choice.id, visitedPermissionPages) &&
                  !isSelected ? (
                    <RiCheckboxCircleFill
                      style={{
                        color: "#74B587",
                        fontSize: "2.1rem",
                        flexShrink: 0,
                        border: "2px solid #transparent",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <RiArrowRightSLine
                      style={{
                        background: isSelected ? "#74B587" : "transparent",
                        color: isSelected ? "white" : "#DBDBDB",
                        border: isSelected
                          ? "2px solid #74B587"
                          : "2px solid #DBDBDB",
                        borderRadius: "50%",
                        fontSize: "1.6rem",
                        marginRight: "4px",
                      }}
                    />
                  )}
                </Flex>
              );
            })}
          </Box>
        )}

        {getContent()}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "flex-end",
          flexDirection: isMobile ? "column-reverse" : "row",
          alignItems: isMobile ? "initial" : "flex-end",
          height: "100%",
        }}
      >
        <Button
          variant="white"
          className="button_submit"
          extraPadding
          onClick={() => {
            if (!isMobile) setIsManagingPermissions(false);

            if (selectedSubChoice) {
              setSelectedSubChoice(null);
              setIsBackButton(true);
            } else if (selectedChoice) {
              setSelectedChoice(null);
              setIsBackButton(false);
            } else {
              setIsManagingPermissions(false);
            }
          }}
        >
          {isBackButton || !isMobile ? (
            <Trans>Nazaj</Trans>
          ) : (
            <Trans>Prekliči</Trans>
          )}
        </Button>
        <Button
          onClick={() => {
            if (user) {
              setIsManagingPermissions(false);
              setIsBackButton(false);
            } else {
              goToNextChoice();
            }
          }}
        >
          <Trans>Nadaljuj</Trans>
        </Button>
      </div>
    </div>
  );
};
