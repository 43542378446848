import useAccountInfo from "@/hooks/useAccountInfo";
import useFeature from "@/hooks/useFeature";
import { useConfirmCustomer2FA } from "@/lib/api-client/paths/customer";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Divider,
  Flex,
  Loader,
  Menu,
  Text,
  Textarea,
  UnstyledButton,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { isSafari } from "react-device-detect";
import { FaEdit, FaEllipsisV, FaTrash } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { MdCopyAll } from "react-icons/md";
import { isValidPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import { fullPhoneNumberToNumberAndCountryCode } from "../../../../Utilities";
import { apiUpdateCustomer } from "../../../../apiCalls";
import { useCustomers } from "../../../../hooks/useCustomers";
import { useFormFields } from "../../../../hooks/useFormFields";
import useUserPermissionsStore from "../../../../stores/useUserPermissionsStore";
import Analytics from "./Analytics/Analytics";
import CustomerAppointments from "./CustomerAppointmentsList/CustomerAppointments";
import EditingCustomerForm from "./EditingCustomerForm";

const CustomerCard = ({ customerId, handleClose }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { deleteCustomer, setCustomerData, customerData, refreshCustomerData } =
    useCustomers();

  const {
    mutateAsync: confirmCustomer2FA,
    isLoading: isConfirmingCustomer2FA,
  } = useConfirmCustomer2FA();

  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [fields, setValues, resetFields, setFields] = useFormFields({});

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { isFeatureEnabled } = useFeature();

  const { accountInfo } = useAccountInfo();

  useEffect(() => {
    async function fetchCustomerData() {
      refreshCustomerData(customerId);
    }

    if (customerId) {
      fetchCustomerData();
    }
  }, [customerId]);

  const deleteCustomerHandler = async () => {
    await deleteCustomer(customerData.customerId);

    handleClose({ isCustomerDeleted: true });
  };

  const submitCustomerFormHandler = async () => {
    const token = localStorage.getItem("usr_token");

    const user = { ...fields };

    const phoneNumber = fullPhoneNumberToNumberAndCountryCode(user.gsm);
    if (phoneNumber != null) {
      user.gsm = phoneNumber.gsm;
      user.countryCode = phoneNumber.countryCode;
    } else {
      user.gsm = null;
      user.countryCode = customerData.countryCode;
    }

    const cData = {
      ...customerData,
    };
    delete cData.upcommingAppointments;
    delete cData.completedAppointments;

    const response = await apiUpdateCustomer(
      {
        ...cData,
        ...user,
        birthday: user.birthday
          ? dayjs(user.birthday).format("YYYY-MM-DD")
          : null,
        customerId: customerData.customerId,
        notes: user.notes ?? "",
      },
      token,
    );

    if (response.status === 200) {
      setCustomerData(() => {
        return {
          ...customerData,
          ...user,
          customerId: customerData.customerId,
        };
      });
    }

    setIsEditingCustomer(false);
  };

  const openDeleteConfirmModal = () => {
    modals.openConfirmModal({
      title: t`Izbriši osebo`,
      children: (
        <Text size="sm">
          Oseba bo izbrisana. Termini te osebe bodo ostali v analitiki. Želite
          nadaljevati?
        </Text>
      ),
      labels: { confirm: t`Potrdi`, cancel: t`Prekliči` },
      onConfirm: deleteCustomerHandler,
      withinPortal: true,
      zIndex: 10000,
      centered: true,
    });
  };

  const handleConfirmGsmEmailClick = async (type) => {
    modals.openConfirmModal({
      title: t`Ali ste prepričani, da želite potrditi ${type} za 2FA sistem?`,
      labels: { confirm: t`Potrdi`, cancel: t`Prekliči` },
      onConfirm: async () => {
        const response = await confirmCustomer2FA({
          customerId: customerData.customerId,
          body: {
            confirmEmail: type === "email",
            confirmGsm: type === "gsm",
          },
        });

        if (response.message === "OK") {
          notifications.show({
            title: t`Uspešno potrjeno`,
            message:
              type === "gsm"
                ? t`Telefonska številka je bila uspešno potrjena.`
                : t`Elektronski naslov je bil uspešno potrjen.`,
          });
        }

        refreshCustomerData(customerId);
      },
      withinPortal: true,
      zIndex: 10000,
      centered: true,
    });
  };

  useLingui();

  return (
    <div style={{ paddingTop: "15px" }}>
      {!customerData && <Loader />}

      {customerData && (
        <>
          {!isMobile ? (
            <TopRow>
              <TopNameWrapper isMobile={isMobile}>
                <Text variant={TextVariant.Heading} c={Color.Brand}>
                  {customerData.name + " "}
                  {customerData.lastName}
                </Text>
                {isEditingCustomer ? (
                  <>
                    <CustomIcon
                      style={{
                        padding: "0px",
                      }}
                      onClick={() => {
                        setIsEditingCustomer(false);
                      }}
                    >
                      <FaCircleXmark />
                    </CustomIcon>
                    <Button
                      onClick={() => {
                        submitCustomerFormHandler();
                      }}
                      disabled={
                        !fields.name ||
                        (fields.gsm && !isValidPhoneNumber(fields.gsm))
                      }
                    >
                      <Trans>Shrani</Trans>
                    </Button>
                  </>
                ) : (
                  !!permissions.manage_appointments && (
                    <CustomIcon
                      style={{
                        padding: "0px",
                      }}
                      onClick={() => {
                        setIsEditingCustomer(true);
                      }}
                    >
                      <FaEdit />
                    </CustomIcon>
                  )
                )}
              </TopNameWrapper>

              <Button
                variant="white"
                leftSection={<FaTrash />}
                c={Color.Error}
                onClick={() => openDeleteConfirmModal()}
              >
                <Trans>Izbriši osebo</Trans>
              </Button>
            </TopRow>
          ) : (
            <TopRow isMobile={isMobile}>
              <TopNameWrapper isMobile={isMobile}>
                <CustomerName>
                  {customerData.name + " "}
                  {customerData.lastName}
                </CustomerName>
              </TopNameWrapper>

              {!!permissions.manage_appointments && !isEditingCustomer && (
                <Menu position="left-start" withArrow>
                  <Menu.Target>
                    <Button variant="white">
                      <FaEllipsisV />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      leftSection={<FaEdit />}
                      onClick={() => {
                        setIsEditingCustomer(true);
                      }}
                    >
                      <Trans>Uredi osebne podatke</Trans>
                    </Menu.Item>
                    <Menu.Item
                      leftSection={<FaTrash />}
                      onClick={() => {
                        openDeleteConfirmModal();
                      }}
                    >
                      <Trans>Izbriši osebo</Trans>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </TopRow>
          )}

          <p
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              color: "transparent",
              fontSize: ".75rem",
            }}
          >
            {customerData?.customerId}
          </p>

          <Content isMobile={isMobile}>
            <LeftSideModal>
              <Details>
                {isEditingCustomer ? (
                  <EditingCustomerForm
                    submitCustomerFormHandler={submitCustomerFormHandler}
                    setIsEditingCustomer={setIsEditingCustomer}
                    fields={fields}
                    setFields={setFields}
                    setValues={setValues}
                    isMobile={isMobile}
                    customerData={customerData}
                  />
                ) : (
                  <Flex
                    direction={isMobile ? "column" : "row"}
                    align={"flex-start"}
                  >
                    <Col
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <Col>
                        <Text
                          variant={TextVariant.Body}
                          c={Color.SecondaryText}
                        >
                          <Trans>Email</Trans>
                        </Text>
                        <Text variant={TextVariant.Body}>
                          <a href={`mailto:${customerData.email}`}>
                            {customerData.email || t`Ni emaila`}
                          </a>
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          variant={TextVariant.Body}
                          c={Color.SecondaryText}
                        >
                          <Trans>Telefonska številka</Trans>
                        </Text>
                        <Text variant={TextVariant.Body}>
                          <a href={`tel:${customerData.gsm}`}>
                            {customerData.gsm || t`Ni telefonske številke`}
                          </a>
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          variant={TextVariant.Body}
                          c={Color.SecondaryText}
                        >
                          <Trans>Datum rojstva</Trans>
                        </Text>
                        <Text variant={TextVariant.Body}>
                          {customerData.birthday
                            ? dayjs(customerData.birthday).format("D. M. YYYY")
                            : t`Datum rojstva ni vnešen`}
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          variant={TextVariant.Body}
                          c={Color.SecondaryText}
                        >
                          <Trans>Povezava za prenaročanje</Trans>:
                        </Text>
                        <UnstyledButton
                          py={"xs"}
                          px={"xs"}
                          c={"teal"}
                          onClick={() => {
                            const rescheduleLink = `${
                              import.meta.env.VITE_FORM_URL
                            }/customer/${customerData.customerHash}`;

                            navigator.clipboard.writeText(rescheduleLink);

                            notifications.show({
                              title: t`Povezava je bila kopirana!`,
                              message: t`Povezavo lahko prilepite v sporočilo stranki.`,
                            });
                          }}
                        >
                          <Flex gap={"xs"} align={"center"}>
                            <MdCopyAll /> <Trans>Povezava do forme</Trans>
                          </Flex>
                        </UnstyledButton>
                      </Col>
                      <Col>
                        <Text
                          variant={TextVariant.Body}
                          c={Color.SecondaryText}
                        >
                          <Trans>Datum zadnjega obiska</Trans>
                        </Text>
                        <Text variant={TextVariant.Body}>
                          {customerData.completedAppointments.length > 0
                            ? dayjs(
                                customerData.completedAppointments[0].startTime,
                              ).format("DD. MM. YYYY")
                            : t`Še ni obiska`}
                        </Text>
                      </Col>
                      {isFeatureEnabled("2FA") && (
                        <Col
                          style={{
                            marginBottom: "0px",
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5rem",
                          }}
                        >
                          {accountInfo.twoFactorAuthChannel === "sms" &&
                            customerData.gsm && (
                              <>
                                {customerData.isGsmConfirmed ? (
                                  <Text variant={TextVariant.BodyEmphasized}>
                                    <Trans>(2FA) Gsm potrjen</Trans>
                                  </Text>
                                ) : (
                                  <Button
                                    variant="light"
                                    onClick={() =>
                                      handleConfirmGsmEmailClick("gsm")
                                    }
                                  >
                                    <Trans>
                                      (2FA) Potrdi telefonsko številko
                                    </Trans>
                                  </Button>
                                )}
                              </>
                            )}

                          {accountInfo.twoFactorAuthChannel === "email" &&
                            customerData.email && (
                              <>
                                {customerData.isEmailConfirmed ? (
                                  <Text variant={TextVariant.BodyEmphasized}>
                                    <Trans>(2FA) Email potrjen</Trans>
                                  </Text>
                                ) : (
                                  <Button
                                    variant="light"
                                    onClick={() =>
                                      handleConfirmGsmEmailClick("email")
                                    }
                                  >
                                    <Trans>
                                      (2FA) Potrdi elektronski naslov
                                    </Trans>
                                  </Button>
                                )}
                              </>
                            )}
                        </Col>
                      )}
                    </Col>
                    <Col>
                      <Col>
                        <Text
                          variant={TextVariant.BodyEmphasized}
                          c={Color.SecondaryText}
                        >
                          <Trans>Komentar</Trans>
                        </Text>

                        <Textarea
                          value={customerData.notes || "Ni komentarja"}
                          autosize
                          w={"100%"}
                          minRows={4}
                        ></Textarea>
                      </Col>
                    </Col>
                  </Flex>
                )}
              </Details>
              <CustomerAppointments
                refreshCustomerData={refreshCustomerData}
                setCustomerData={setCustomerData}
                customerData={customerData}
                isMobile={isMobile}
              />
            </LeftSideModal>

            {!!permissions.view_analytics && (
              <>
                <Divider orientation={"vertical"} mx={"md"} />
                <Analytics
                  completedAppointments={
                    customerData.completedAppointments || []
                  }
                  isMobile={isMobile}
                />
              </>
            )}
          </Content>
        </>
      )}
    </div>
  );
};

const MobileDropDown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  position: absolute;
  top: 100px;
  background-color: #ffffff;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 10px;
  right: 50px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 0.7rem;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  gap: 2rem;
  padding-top: 0;
`;

export const Modal = styled.div`
  text-align: left;
  padding: 35px 20px;
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: fixed;
  top: 1rem;
  left: ${(props) => (props.isMobile ? "0" : isSafari ? "80px" : "1rem")};
  right: 1rem;
  bottom: 1rem;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1200;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;

  @media (max-width: 768px) {
    border-radius: 0px;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export const IconsContainer = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
export const CustomIcon = styled.div`
  color: #8e8e8e;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 5px;

  label {
    font-size: 1rem;
    cursor: pointer;
  }

  :hover {
    color: #000000;
  }
`;

export const TopNameWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  max-width: 50vw;

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 90vw;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const CustomerName = styled.h1`
  font-size: 1.5rem;
  color: #74b587;
  font-weight: 600;
  margin: 0;
  line-break: anywhere;
`;

const LeftSideModal = styled.div`
  width: 100%;
`;

export { Col };

export default CustomerCard;
