import { getStatusIcon } from "@/pages/Dashboard/Calendar/utils";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Accordion, Flex, Text, Transition } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useCalendarStore } from "../../pages/Dashboard/Calendar/store/useCalendarStore";
import { AlertChip } from "../AlertChip/AlertChip";
import LoadingSpinner from "../Dashboard/LoadingSpinner/LoadingSpinner";
import CustomerList from "./components/CustomerList";
import DateTimeSection from "./components/DateTimeSection";
import Form from "./components/Form";
import SelectedServiceList from "./components/SelectedServiceList";
import ServiceSelect from "./components/ServiceSelect";
import useAppointmentModal from "./useAppointmentModal";
import { getAppointmentEndTime, isAppointmentInPast } from "./utils";
import { useAppointmentModalStore } from "./useAppointmentModalStore_";

const AppointmentModal = ({
  appointmentId,
  openCustomerCard,
  IS_GLOWUP_USER = false,
  IS_ILLUME_USER = false,
}) => {
  useLingui();
  const { setIsExpandedModal, serviceTags, calendarData } = useCalendarStore(
    (state) => state,
  );

  const shifts = calendarData?.allShifts;

  const {
    appointmentData,
    isFetchingData,
    handleDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    services,
    selectedServices,
    setSelectedServices,
    confirmedServiceSelection,
    handleConfirmServiceSelection,
    selectedUserAppointments,
    handleUserAppointmentSelect,
    setSelectedUserAppointments,
    handleSubmitAppointmentForm,
    handleRemoveCustomerFromPreScheduledAppointment,
  } = useAppointmentModal(appointmentId);

  const { requiredTags, isTagCombinationValid } = useAppointmentModalStore();

  const isNewAppointment = appointmentId === -1;
  const dateObject = dayjs(appointmentData?.startTime);

  const isInPast = useMemo(
    () => isAppointmentInPast(dateObject.format("YYYY-MM-DD HH:mm")),
    [appointmentData?.startTime],
  );

  const endTime = useMemo(
    () => getAppointmentEndTime(appointmentData?.startTime, selectedServices),
    [appointmentData?.startTime, selectedServices],
  );

  const isPreScheduled = appointmentData?.isPreScheduled;
  useEffect(() => {
    setIsExpandedModal(isPreScheduled);
  }, [appointmentData?.isPreScheduled]);

  const getContent = () => {
    if (isFetchingData) return <LoadingSpinner />;
    if (!appointmentData) return <div>Invalid appointment</div>;

    if (isNewAppointment && !confirmedServiceSelection) {
      return (
        <ServiceSelect
          services={services}
          serviceTags={serviceTags}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          setConfirmedServiceSelection={handleConfirmServiceSelection}
        />
      );
    }

    const showForm = isNewAppointment || services.length > 0;
    const disableFormInputs = selectedUserAppointments.length > 0;

    const selectedCustomerId = appointmentData.customers.find(
      (customer) => customer.userAppointmentId === selectedUserAppointments[0],
    )?.customerId;

    return (
      <div>
        <Transition
          mounted={showForm}
          transition={"pop"}
          duration={200}
          timingFunction={"cubic-bezier(.25,.8,.25,1)"}
        >
          {(styles) => (
            <div style={styles}>
              <Form
                disableUnEditableFields={disableFormInputs}
                customerData={appointmentData.customers.find(
                  (customer) =>
                    customer.userAppointmentId === selectedUserAppointments[0],
                )}
                notificationSchedule={appointmentData.customers
                  .filter((c) => c.customerId === selectedCustomerId)
                  .flatMap((c) => c.notificationSchedule)}
                notificationLog={appointmentData.customers
                  .filter((c) => c.customerId === selectedCustomerId)
                  .flatMap((c) => c.notificationLog)}
                handleSubmitAppointmentForm={handleSubmitAppointmentForm}
                isNewAppointment={isNewAppointment}
                selectedServices={selectedServices}
                IS_GLOWUP_USER={IS_GLOWUP_USER}
                IS_ILLUME_USER={IS_ILLUME_USER}
              />
            </div>
          )}
        </Transition>
      </div>
    );
  };

  const successes = [];

  const warnings = [];

  const errors = [];
  if (isInPast) {
    if (isNewAppointment) {
      errors.push({
        message: t`Termin bo ustvarjen v preteklosti!`,
        variant: "filled",
      });
    } else {
      errors.push({
        message: t`Termin je v preteklosti!`,
        variant: "filled",
      });
    }
  }

  if (requiredTags.length && !isTagCombinationValid) {
    errors.push({
      message: t`Potrebujete še storitev iz ${requiredTags.map((t) => serviceTags.find((st) => st.tagId === t)?.name).join(", ")}`,
    });
  }

  const { days } = calendarData;

  if (appointmentData?.startTime) {
    const date = dayjs(appointmentData.startTime, "YYYY-MM-DD HH:mm").format(
      "YYYY-MM-DD",
    );

    const selectedDay = days.find((day) => day.date === date);

    if (selectedDay?.isHoliday) {
      errors.push({
        message: t`Termin je na praznik!`,
        variant: "filled",
      });
    }
  }

  const alerts = {
    successes,
    warnings,
    errors,
  };

  const selectedPreScheduledCustomer = appointmentData?.customers?.find(
    (customer) => customer.userAppointmentId === selectedUserAppointments[0],
  );

  return (
    <>
      <Flex m={"5px 0"} gap={"5px"} wrap={"wrap"} mt={"15px"}>
        {alerts && (
          <>
            {alerts.errors.map((alert, index) => (
              <AlertChip text={alert.message} variant="error" key={index} />
            ))}
            {alerts.warnings.map((alert, index) => (
              <AlertChip text={alert.message} variant="warning" key={index} />
            ))}
            {alerts.successes.map((alert, index) => (
              <AlertChip text={alert.message} variant="success" key={index} />
            ))}
          </>
        )}
      </Flex>

      {((isPreScheduled && selectedUserAppointments.length === 1) ||
        !isPreScheduled) && (
        <PaymentDetails
          customer={
            isPreScheduled
              ? selectedPreScheduledCustomer
              : appointmentData?.customers[0]
          }
        />
      )}

      {isFetchingData || appointmentData == null ? (
        <LoadingSpinner />
      ) : (
        <Flex
          direction={{
            base: "column",
            sm: "row",
          }}
          gap={"2rem"}
        >
          <Flex direction={"column"} style={{ flex: 1 }}>
            {(confirmedServiceSelection || !isNewAppointment) && (
              <SelectedServiceList
                services={services}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                isPreScheduled={appointmentData.isPreScheduled}
                isNewAppointment={isNewAppointment}
              />
            )}
            <DateTimeSection
              date={appointmentData.startTime}
              startTime={dateObject?.format("HH:mm") ?? "00:00"}
              endTime={endTime ?? "00:00"}
              handleDateChange={handleDateChange}
              handleStartTimeChange={handleStartTimeChange}
              handleEndTimeChange={handleEndTimeChange}
              canChangeEndTime={selectedServices.length <= 1}
              isPreScheduled={appointmentData.isPreScheduled}
            />
            {getContent()}
          </Flex>
          {!!isPreScheduled && (
            <CustomerList
              customers={appointmentData.customers}
              selectedUserAppointments={selectedUserAppointments}
              setSelectedUserAppointments={setSelectedUserAppointments}
              handleUserAppointmentSelect={handleUserAppointmentSelect}
              handleRemoveCustomerFromPreScheduledAppointment={
                handleRemoveCustomerFromPreScheduledAppointment
              }
              IS_GLOWUP_USER={IS_GLOWUP_USER || IS_ILLUME_USER}
              openCustomerCard={openCustomerCard}
            />
          )}
        </Flex>
      )}
    </>
  );
};

const PaymentDetails = ({ customer }) => {
  if (customer == null || customer.paymentStatus == null) {
    return null;
  }

  return (
    <Accordion
      styles={{
        root: {
          backgroundColor:
            customer.paymentInfo.paymentStatus === "succeeded"
              ? "#E6F5E8"
              : "#FFFBEB",
          borderRadius: "4px",
        },
        control: {
          backgroundColor:
            customer.paymentInfo.paymentStatus === "succeeded"
              ? "#E6F5E8"
              : "#FFFBEB",
        },
        chevron: {
          color:
            customer.paymentInfo.paymentStatus === "succeeded"
              ? Color.Success
              : Color.Warning,
        },
        panel: {
          color: "#505050",
        },
        item: {
          border: "none",
        },
      }}
    >
      <Accordion.Item key={"payment"} value={"payment"}>
        <Accordion.Control>
          <Flex w={"100%"} justify={"space-between"} align={"center"}>
            <Flex align={"center"} gap={"xs"}>
              <Text
                c={
                  customer.paymentInfo.paymentStatus === "succeeded"
                    ? Color.Success
                    : Color.Warning
                }
                variant={TextVariant.CaptionEmphasized}
                mt={"5px"}
              >
                {getStatusIcon(
                  customer.paymentInfo.paymentStatus === "succeeded"
                    ? "success"
                    : "warning",
                )}
              </Text>
              <Text
                c={
                  customer.paymentInfo.paymentStatus === "succeeded"
                    ? Color.Success
                    : Color.Warning
                }
                variant={TextVariant.CaptionEmphasized}
              >
                <Trans>Spletno plačilo</Trans>
              </Text>
            </Flex>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel>
          <Flex direction={"column"} gap={4}>
            <Flex gap={6}>
              <Text variant={TextVariant.CaptionEmphasized}>Stanje</Text>
              <Text variant={TextVariant.Caption}>
                {customer.paymentInfo.paymentStatus === "succeeded"
                  ? t`Termin potrjen s plačilom preko spleta.`
                  : t`Plačilo termina v obdelavi`}
              </Text>
            </Flex>
            <Flex gap={6}>
              <Text variant={TextVariant.CaptionEmphasized}>Številka</Text>
              <Text variant={TextVariant.Caption}>
                {customer.userAppointmentId}
              </Text>
            </Flex>
            {customer.paymentInfo.amountPaid != null &&
            customer.paymentInfo.leftToPay != null ? (
              <>
                <Flex gap={6}>
                  <Text variant={TextVariant.CaptionEmphasized}>
                    Že plačano
                  </Text>
                  <Text variant={TextVariant.Caption}>
                    {customer.paymentInfo.amountPaid}
                  </Text>
                </Flex>
                <Flex gap={6}>
                  <Text variant={TextVariant.CaptionEmphasized}>
                    Ostanek za plačilo
                  </Text>
                  <Text variant={TextVariant.Caption}>
                    {customer.paymentInfo.leftToPay}
                  </Text>
                </Flex>
              </>
            ) : (
              <>
                <Flex gap={6}>
                  <Text variant={TextVariant.CaptionEmphasized} c={Color.Error}>
                    <Trans>Podrobnosti o plačilu niso na voljo</Trans>
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default AppointmentModal;
