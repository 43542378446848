import { Color } from "@/types/colors";
import { Flex, Menu, rem } from "@mantine/core";
import {
  HR as CroatianFlag,
  GB as EnglishFlag,
  IT as ItalianFlag,
  RS as SerbianFlag,
  SI as SlovenianFlag,
} from "country-flag-icons/react/3x2";
import React, { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

const supportedLanguages = [
  { value: "sl", label: "Slovenščina", image: SlovenianFlag },
  { value: "hr", label: "Hrvatski", image: CroatianFlag },
  { value: "sr", label: "Srpski", image: SerbianFlag },
  // { value: "de", label: "Deutsch", image: GermanFlag },
  { value: "en", label: "English", image: EnglishFlag },
  { value: "it", label: "Italiana", image: ItalianFlag },
  // { value: "es", label: "Español", image: SpanishFlag },
];

const LocalePicker = ({ onChange, value, style, isOnForm = false }) => {
  const [opened, setOpened] = useState(false);
  const selectedLanguage =
    supportedLanguages.find((lang) => lang.value === value) ||
    supportedLanguages[0]; // Default to the first language if not found

  const items = supportedLanguages.map((lang) => (
    <Menu.Item
      leftSection={React.createElement(lang.image, { width: 18, height: 18 })}
      onClick={() => {
        onChange(lang.value);
        setOpened(false);
      }}
      key={lang.value}
    >
      {isOnForm ? lang.value : lang.label}
    </Menu.Item>
  ));

  return (
    <Menu
      style={style}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
      styles={{
        label: {
          fontWeight: 500,
          fontSize: "14px",
        },

        icon: {
          transition: "transform 150ms ease",
          transform: opened ? "rotate(180deg)" : "rotate(0deg)",
        },
      }}
    >
      <Menu.Target>
        <Flex
          align={"center"}
          style={{
            // padding: "0.5rem",
            width: isOnForm ? rem(110) : rem(200),
            display: "flex",
            zIndex: 99999,
            justifyContent: "space-between",
            alignItems: "center",
            padding: `var(--mantine-spacing-xs) var(--mantine-spacing-md)`,
            borderRadius: "var(--mantine-radius-md)",
            border: `1px solid ${Color.Border}`,
            transition: "background-color 150ms ease",
            backgroundColor: opened ? Color.Border : "white",
            cursor: "pointer",

            "&:hover": {
              backgroundColor: Color.Border,
            },
          }}
          gap={"xs"}
        >
          <Flex spacing="xs" align="center" gap={"md"}>
            {React.createElement(selectedLanguage.image, {
              width: 22,
              height: 22,
            })}
            <span>
              {isOnForm ? selectedLanguage.value : selectedLanguage.label}
            </span>
          </Flex>
          <RiArrowDownSLine size="1rem" stroke={1.5} />
        </Flex>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
};

export default LocalePicker;
