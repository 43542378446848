import { ActionIcon, Flex, NumberInput, Text } from "@mantine/core";
import { RiAddLine, RiSubtractFill } from "react-icons/ri";
import styled from "styled-components";

const StepperNumberInput = ({
  value,
  onChange,
  step,
  unitText,
  label,
  min,
  max,
  disabled,
  ...props
}) => {
  return (
    <Wrapper>
      <Text size={"sm"} variant="caption">
        {label}
      </Text>
      <Flex align={"center"} gap={"2px"}>
        {disabled ? null : (
          <ActionIcon
            className={"action-icon"}
            onClick={() => {
              if (min != null && value - step < min) {
                onChange(min);
                return;
              }
              onChange(value - step);
            }}
          >
            <RiSubtractFill />
          </ActionIcon>
        )}
        <InputWrapper>
          <NumberInput
            value={value}
            onChange={onChange}
            hideControls={true}
            variant={"filled"}
            {...props}
            disabled={disabled}
            label={null}
            style={{ width: "80px" }}
            min={min}
            max={max}
          />

          <span className={"unit-text"}>{unitText}</span>
        </InputWrapper>
        {disabled ? null : (
          <ActionIcon
            className={"action-icon"}
            onClick={() => {
              if (max != null && value + step > max) {
                onChange(max);
                return;
              }
              onChange(value + step);
            }}
          >
            <RiAddLine />
          </ActionIcon>
        )}
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .action-icon {
    background: #f8f8f8;
    height: 36px;
    width: 36px;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .unit-text {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;

    font-size: 14px;
    color: #6b7280;
  }
`;

export default StepperNumberInput;
