import type { TimeType } from "@/../../shared/types/time-type";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Checkbox, Flex, Text } from "@mantine/core";
import dayjs from "dayjs";
import { ControlledTimeInput } from "./ControlledTimeInput";

type TimeSpanRowType = {
  fromTimeValue: TimeType | Date | "";
  toTimeValue: TimeType | Date | "";
  onFromTimeChange: (time: TimeType) => void;
  onToTimeChange: (time: TimeType) => void;
  isAllDay?: boolean;
  setAllDay?: () => void;
  minTime?: TimeType;
  maxTime?: TimeType;
  belowMinErrorText?: string;
  aboveMaxErrorText?: string;
  error?: string;
  disableTo?: boolean;
};

const TimeSpanRow = ({
  fromTimeValue,
  toTimeValue,
  onFromTimeChange,
  onToTimeChange,
  isAllDay,
  setAllDay,
  minTime,
  maxTime,
  belowMinErrorText,
  aboveMaxErrorText,
  error,
  disableTo,
}: TimeSpanRowType) => {
  const finalFromTimeValue =
    fromTimeValue instanceof Date
      ? (dayjs(fromTimeValue).format("HH:mm") as TimeType)
      : fromTimeValue;
  const finalToTimeValue =
    toTimeValue instanceof Date
      ? (dayjs(toTimeValue).format("HH:mm") as TimeType)
      : toTimeValue;

  useLingui();
  let errorText = error ?? "";
  if (
    finalFromTimeValue &&
    finalToTimeValue &&
    finalFromTimeValue > finalToTimeValue
  ) {
    errorText = t`Prva ura ne sme biti pred drugo uro`;
  } else if (
    finalFromTimeValue &&
    finalToTimeValue &&
    finalFromTimeValue === finalToTimeValue
  ) {
    errorText = t`Časa ne smeta biti enaka`;
  } else if (finalFromTimeValue && minTime && finalFromTimeValue < minTime) {
    errorText =
      belowMinErrorText ?? t`Začetni čas mora biti večji od ${minTime}`;
  } else if (finalToTimeValue && maxTime && finalToTimeValue > maxTime) {
    errorText =
      aboveMaxErrorText ?? t`Končni čas mora biti manjši od ${maxTime}`;
  }

  return (
    <>
      {errorText && (
        <Text size={"xs"} inline span c={"red"}>
          * {errorText}
        </Text>
      )}
      <Flex
        direction={"row"}
        align={"center"}
        justify={"space-between"}
        gap={"sm"}
        wrap={"wrap"}
      >
        <Flex direction={"row"} align={"center"} gap={"xs"}>
          <Text c={"#8C8C8C"} size={"12px"}>
            <Trans>Od</Trans>:
          </Text>
          <ControlledTimeInput
            timeValue={finalFromTimeValue}
            onTimeChange={onFromTimeChange}
            minTime={minTime}
            maxTime={maxTime}
          />
          <Text c={"#8C8C8C"} size={"12px"}>
            <Trans>do</Trans>:
          </Text>
          <ControlledTimeInput
            timeValue={finalToTimeValue}
            onTimeChange={onToTimeChange}
            minTime={minTime}
            maxTime={maxTime}
            disabled={disableTo}
          />
        </Flex>

        {setAllDay && (
          <Checkbox
            checked={isAllDay}
            label={t`Cel dan`}
            radius={"xl"}
            onClick={setAllDay}
          />
        )}
      </Flex>
    </>
  );
};

export default TimeSpanRow;
