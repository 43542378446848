import { ActionIcon, Tabs } from "@mantine/core";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import FeaturesTab from "./FeaturesTab/FeaturesTab";
import { FormsTab } from "./FormsTab/FormsTab";
import { GeneralTab } from "./GeneralTab/GeneralTab";
import { ImportTab } from "./ImportTab/ImportTab";
import { LocationsTab } from "./LocationsTab/LocationsTab";
import { NotificationsTab } from "./NotificationsTab/NotificationsTab";
import { ServicesTab } from "./ServicesTab/ServicesTab";
import { TimeOffsTab } from "./TimeOffsTab";
import { UsersTab } from "./UsersTab/UsersTab";
export const ClientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return null;

  const selectedClientId = Number(id);

  return (
    <>
      <ActionIcon onClick={() => navigate("..")}>
        <IoMdArrowBack size="24px"></IoMdArrowBack>
      </ActionIcon>
      <Tabs defaultValue={"general"} keepMounted={false}>
        <Tabs.List>
          <Tabs.Tab value="general">Osnovni podatki podjetja</Tabs.Tab>
          <Tabs.Tab value="features">Features</Tabs.Tab>
          <Tabs.Tab value="users">Zaposleni</Tabs.Tab>
          <Tabs.Tab value="notifications">Notifikacije</Tabs.Tab>
          <Tabs.Tab value="forms">Forme</Tabs.Tab>
          <Tabs.Tab value="locations">Lokacije</Tabs.Tab>
          <Tabs.Tab value="services">Storitve</Tabs.Tab>
          <Tabs.Tab value="timeoffs">Pavze</Tabs.Tab>
          <Tabs.Tab value="import">Uvoz</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="general">
          <GeneralTab selectedClientId={selectedClientId}></GeneralTab>
        </Tabs.Panel>
        <Tabs.Panel value="features">
          <FeaturesTab selectedClientId={selectedClientId}></FeaturesTab>
        </Tabs.Panel>
        <Tabs.Panel value="users">
          <UsersTab clientId={selectedClientId}></UsersTab>
        </Tabs.Panel>
        <Tabs.Panel value="notifications">
          <NotificationsTab clientId={selectedClientId}></NotificationsTab>
        </Tabs.Panel>
        <Tabs.Panel value="forms">
          <FormsTab clientId={selectedClientId}></FormsTab>
        </Tabs.Panel>
        <Tabs.Panel value="locations">
          <LocationsTab clientId={selectedClientId}></LocationsTab>
        </Tabs.Panel>
        <Tabs.Panel value="services">
          <ServicesTab clientId={selectedClientId}></ServicesTab>
        </Tabs.Panel>
        <Tabs.Panel value="timeoffs">
          <TimeOffsTab clientId={selectedClientId}></TimeOffsTab>
        </Tabs.Panel>
        <Tabs.Panel value="import">
          <ImportTab clientId={selectedClientId}></ImportTab>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
