import { useMutation, useQuery } from "@tanstack/react-query";

import {
  DeleteService,
  GetService,
  GetServices,
  PostService,
  PostServiceSortOrder,
} from "@/server-types";
import { del, get, post } from "../request-helpers";

/**
 * GET /service
 */
export const useGetServices = (query: GetServices["query"]) => {
  return useQuery({
    queryKey: ["services", query],
    queryFn: () => get<GetServices>("/service", query),
  });
};

/**
 * POST /service
 */
export const usePostService = () => {
  return useMutation({
    mutationKey: ["services"],
    mutationFn: (service: PostService["body"]) =>
      post<PostService>("/service", service),
  });
};

/**
 * GET /service/:id
 */
export const useGetService = (id: number) => {
  return useQuery({
    queryKey: ["service", id],
    queryFn: () => get<GetService>(`/service/${id}`),
    enabled: id !== -1,
  });
};

/**
 * DELETE /service/:id
 */
export const useDeleteService = () => {
  return useMutation({
    mutationKey: ["services"],
    mutationFn: (id: number) => del<DeleteService>(`/service/${id}`),
  });
};

/**
 * POST /service/sort-order
 */

export const usePostServiceSortOrder = (services: number[]) => {
  return useMutation({
    mutationKey: ["postServicesOrder", services],
    mutationFn: () =>
      post<PostServiceSortOrder>(`/service/sort-order`, { services }),
  });
};
