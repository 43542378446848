import { useQuery } from "@tanstack/react-query";
import { get } from "../request-helpers";
import { GetAppVersion } from "../../../../../server/src/types";

export const useGetAppVersion = () => {
  return useQuery({
    queryKey: ["version"],
    queryFn: () => get<GetAppVersion>("/app/version"),
    refetchInterval: 60000,
  });
};
