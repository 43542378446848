import { i18n } from "@lingui/core";
import { type ClassValue, clsx } from "clsx";
import { isEmpty } from "lodash";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getLegalPages = ({ language }: { language?: string }) => {
  const finalLocale = language || i18n.locale;

  let termsAndConditionsUrl = "";
  let privacyPolicyUrl = "";

  switch (finalLocale) {
    case "sl":
      privacyPolicyUrl = "https://lime-booking.si/izjava-o-zasebnosti/";
      termsAndConditionsUrl = "https://lime-booking.si/pogoji-poslovanja";
      break;
    case "en":
      privacyPolicyUrl = "https://lime-booking.com/izjava-o-zasebnosti/";
      termsAndConditionsUrl = "https://lime-booking.com/terms-and-conditions";
      break;
    default:
      privacyPolicyUrl = "https://lime-booking.si/izjava-o-zasebnosti/";
      termsAndConditionsUrl = "https://lime-booking.si/pogoji-poslovanja";
      break;
  }

  return {
    termsAndConditionsUrl,
    privacyPolicyUrl,
  };
};

export function rgbaStringToHex(rgbaString: string): string {
  // Extract the RGBA components using a regular expression
  const match = rgbaString.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/,
  );

  if (!match || !match[1] || !match[2] || !match[3]) {
    throw new Error("Invalid RGBA color format");
  }

  // Parse the RGBA components
  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);
  const a = match[4] !== undefined ? parseFloat(match[4]) : 1; // Default alpha to 1 if not provided

  // Use the rgbaToHex function from the previous example
  return rgbaToHex(r, g, b, a);
}

export function rgbaToHex(r: number, g: number, b: number, a: number): string {
  // Clamp values to ensure they are within the valid range
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));
  a = Math.max(0, Math.min(1, a));

  // Convert each component to a two-digit hexadecimal string
  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");
  const aHex = Math.round(a * 255)
    .toString(16)
    .padStart(2, "0");

  // Combine the components into a single hex color string
  return `#${rHex}${gHex}${bHex}${aHex}`;
}

export function hexToRgba(hex: string): string {
  // Ensure the hex string is valid and normalize it to a standard 6-digit format
  let normalizedHex = hex.replace(/^#/, "");
  if (normalizedHex.length === 3) {
    normalizedHex = normalizedHex
      .split("")
      .map((char) => char + char)
      .join("");
  } else if (normalizedHex.length === 4) {
    normalizedHex = normalizedHex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Extract the components
  const r = parseInt(normalizedHex.slice(0, 2), 16);
  const g = parseInt(normalizedHex.slice(2, 4), 16);
  const b = parseInt(normalizedHex.slice(4, 6), 16);
  let a = 1; // Default alpha value

  // If an alpha component exists, convert it
  if (normalizedHex.length === 8) {
    a = parseInt(normalizedHex.slice(6, 8), 16) / 255;
  }

  // Construct the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function getLocalizedName(
  localizedNames: {
    language: string;
    name: string;
  }[],
  overrideLocale?: string,
): string {
  if (localizedNames.length === 0) {
    return "";
  }

  const { locale: currentLocale } = i18n;

  const locale = overrideLocale || currentLocale;

  const wantedName = localizedNames.find((name) => name.language === locale);
  if (wantedName && !isEmpty(wantedName.name)) {
    return wantedName.name;
  }

  const englishName = localizedNames.find((name) => name.language === "en");
  if (englishName && !isEmpty(englishName.name)) {
    return englishName.name;
  }

  return localizedNames[0]!.name;
}

export function toCents(amount: number): number {
  // Ensure dollars is a string
  const amountString = amount.toString();
  // Split the string on the decimal point
  const parts = amountString.split(".");

  let cents = 0;

  if (parts.length === 1) {
    // No decimal point, just multiply by 100
    cents = Number.parseInt(parts[0]) * 100;
  } else {
    // Combine the whole and fractional parts into cents
    const wholePart = Number.parseInt(parts[0]) * 100;
    let fractionalPart = parts[1];

    // Ensure the fractional part is two digits
    if (fractionalPart.length === 1) {
      fractionalPart += "0";
    } else if (fractionalPart.length > 2) {
      fractionalPart = fractionalPart.substring(0, 2); // Truncate to 2 decimal places
    }

    cents = wholePart + Number.parseInt(fractionalPart);
  }

  return cents;
}
