import { GetLocations } from "@/server-types";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { get } from "../request-helpers";
/**
 * GET /location
 */
export const useGetLocations = (query: GetLocations["query"]) => {
  return useQuery({
    queryKey: ["clientList", query],
    queryFn: () => get<GetLocations>("/location", query),
    placeholderData: keepPreviousData,
  });
};
