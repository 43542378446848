import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Text } from "@mantine/core";
import dayjs from "dayjs";
import styled from "styled-components";
import AttendancePieChart from "./AttendancePieChart";
import ServiceAnalyticsList from "./ServiceAnalyticsList";

const Analytics = ({ isMobile, completedAppointments }) => {
  useLingui();
  const didAttendCount = completedAppointments.filter(
    (appointment) => appointment.customerDidAttend === 1,
  ).length;
  const didntAttendCount = completedAppointments.filter(
    (appointment) => appointment.customerDidAttend === -1,
  ).length;

  const pendingAttendCount = completedAppointments.filter(
    (appointment) => appointment.customerDidAttend === 0,
  ).length;

  const attendanceData = [
    {
      id: t`Prisoten`,
      label:
        didAttendCount === 0
          ? "0"
          : Math.round((didAttendCount / completedAppointments.length) * 100),
      value: didAttendCount,
    },
    {
      id: t`Neprisoten`,
      label:
        didntAttendCount === 0
          ? "0"
          : Math.round((didntAttendCount / completedAppointments.length) * 100),
      value: didntAttendCount,
    },
    {
      id: t`Ni označena prisotnost`,
      label:
        pendingAttendCount === 0
          ? "0"
          : Math.round(
              (pendingAttendCount / completedAppointments.length) * 100,
            ),
      value: pendingAttendCount,
    },
  ];

  // calculate sum of revenue of each day from first appointment to last appointment
  const revenueData = completedAppointments.reduce((acc, appointment) => {
    const date = dayjs(appointment.startTime).format("YYYY-MM-DD");
    const price = appointment.price || 0;
    const index = acc.findIndex((item) => item.x === date);
    if (index === -1) {
      acc.push({
        x: date,
        y: price,
      });
    } else {
      acc[index].y += price;
    }
    return acc;
  }, []);

  revenueData.sort((a, b) => {
    return dayjs(a.x).diff(dayjs(b.x));
  });

  // if there are is no data for a day, add it with 0 revenue
  if (revenueData.length > 0) {
    const days = dayjs(revenueData[-1]?.x).diff(
      dayjs(revenueData[0]?.x),
      "day",
    );
    for (let i = 0; i <= days; i++) {
      const date = dayjs(revenueData[0]?.x).add(i, "day").format("YYYY-MM-DD");
      const index = revenueData.findIndex((item) => item.x === date);
      if (index === -1) {
        revenueData.push({ x: date, y: 0 });
      }
    }
  }

  // set y to cumulative sum in revenueData
  revenueData.reduce((acc, item) => {
    item.y += acc;
    return item.y;
  }, 0);

  revenueData.sort((a, b) => {
    return dayjs(a.x).diff(dayjs(b.x));
  });

  const revenueSum = completedAppointments.reduce((acc, appointment) => {
    return acc + (appointment.price || 0);
  }, 0);

  const serviceAnalyticsData = [];
  for (const appointment of completedAppointments) {
    if (
      !serviceAnalyticsData.some((item) => item.label === appointment.service)
    ) {
      serviceAnalyticsData.push({
        label: appointment.service,
        value: 1,
      });
    } else {
      serviceAnalyticsData.find((item) => item.label === appointment.service)
        .value++;
    }
  }

  return (
    <AnalyticsWrapper isMobile={isMobile}>
      <Text variant={TextVariant.Subheading}>
        <Trans>Analitika</Trans>
      </Text>
      <GraphContainer
        style={{
          height: "fit-content",
        }}
      >
        <AttendancePieChart isMobile={isMobile} data={attendanceData} />
      </GraphContainer>
      <div>
        <GraphContainer
          style={{
            position: "relative",
            flexDirection: "column",
            padding: "0",
            width: "100%",
            height: "90px",
          }}
        >
          <div
            style={{
              display: "inline",
              position: "absolute",
              top: "1rem",
              left: "1rem",
              fontSize: "1.2rem",
            }}
          >
            <h2>
              <Trans>Skupni prihodki</Trans>
            </h2>
            <label>{revenueSum} € </label>
          </div>
          {/*<RevenueLineChart data={revenueDataForChart} />*/}
        </GraphContainer>
      </div>
      <div>
        <GraphContainer
          style={{
            flexDirection: "column",
            height: "fit-content",
            /*   marginBottom: "10rem",*/
            width: "100%",
          }}
        >
          <h2>
            <Trans>Obiskane storitve</Trans>
          </h2>
          {didAttendCount > 0 ? (
            <ServiceAnalyticsList data={serviceAnalyticsData} />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "11.4rem",
              }}
            >
              <p>
                <Trans>Ni podatka.</Trans>
              </p>
            </div>
          )}
        </GraphContainer>
      </div>
    </AnalyticsWrapper>
  );
};

const GraphContainer = styled.div`
  width: 100%;
  height: 15rem;
  background: #fafafa;
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: row;

  label {
    color: #6c7386;
  }
`;

const AnalyticsWrapper = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "60vw")};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    font-size: 1.2rem;
    color: #74b587;
  }
`;

export default Analytics;
