import useAccountInfo from "@/hooks/useAccountInfo";
import { api } from "@/lib/api-client";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "./components/Header";
import Calendar from "./components/calendar/Calendar";
import CalendarModalWrapper from "./components/calendar/CalendarModalWrapper";
import { useCalendarStore } from "./store/useCalendarStore";

const CalendarPage = () => {
  const { accountInfo } = useAccountInfo();
  const loggedInUserId = accountInfo?.userId;

  /**
   * BOTH do:
   * - restrict filter
   * - restrict customer card on appt modal
   * - hide GSM and Email in customer search on appt modal
   *
   * GLOWUP:
   * - Hide email field
   *
   * ILLUME:
   * - Hide GSM field on existing appt; show on new
   */
  const isGlowupUser =
    loggedInUserId === 381 ||
    loggedInUserId === 382 ||
    loggedInUserId === 471 ||
    loggedInUserId === 472 ||
    loggedInUserId === 268 ||
    loggedInUserId === 761 ||
    loggedInUserId === 763 ||
    loggedInUserId === 764 ||
    loggedInUserId === 765 ||
    loggedInUserId === 3434 ||
    loggedInUserId === 3435 ||
    loggedInUserId === 3436 ||
    loggedInUserId === 3437 ||
    loggedInUserId === 3483 ||
    loggedInUserId === 3620 ||
    loggedInUserId === 3297 ||
    loggedInUserId === 3298 ||
    loggedInUserId === 3299 ||
    loggedInUserId === 3300 ||
    loggedInUserId === 3825;
  const isIllumeUser =
    loggedInUserId === 1264 ||
    loggedInUserId === 1268 ||
    loggedInUserId === 976 ||
    loggedInUserId === 977 ||
    loggedInUserId === 1265 ||
    loggedInUserId === 382 ||
    loggedInUserId === 3629 ||
    loggedInUserId === 3902 ||
    loggedInUserId === 3022;

  const {
    dateActions,
    refreshCalendar,
    selectedLocationId,
    setTimeoffs,
    calendarView,
    selectedDate,
  } = useCalendarStore((state) => state);

  const [isMonthCalendar, setIsMonthCalendar] = React.useState(false);

  const {
    data: timeoffs,
    refetch: refetchTimeoffs,
    isLoading: isFetchingTimeoffs,
  } = api.timeoff.useGetTimeoff({
    locationId: selectedLocationId,
    dateFrom: dayjs(selectedDate)
      .startOf(calendarView)
      .format("YYYY-MM-DD HH:mm"),
    dateTo: dayjs(selectedDate).endOf(calendarView).format("YYYY-MM-DD HH:mm"),
  });

  useEffect(() => {
    setTimeoffs(timeoffs);
  }, [timeoffs]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      dateActions.setCalendarView("day");
    } else {
      refreshCalendar().catch(console.log);
    }
  }, []);

  return (
    <>
      <Wrapper isMonthCalendar={isMonthCalendar}>
        <Header IS_GLOWUP_USER={isGlowupUser} IS_ILLUME_USER={isIllumeUser} />

        <Calendar
          setIsMonthCalendar={setIsMonthCalendar}
          isFetchingTimeoffs={isFetchingTimeoffs}
        ></Calendar>
      </Wrapper>

      <CalendarModalWrapper
        refetchTimeoffs={refetchTimeoffs}
        IS_GLOWUP_USER={isGlowupUser}
        IS_ILLUME_USER={isIllumeUser}
      />
    </>
  );
};

const Wrapper = styled.div<{ isMonthCalendar: boolean }>`
  padding-top: 20px;
  padding-bottom: 100px;
  position: relative;
  height: fit-content;

  @media (min-width: 768px) {
    padding: 20px 10px;
  }

  display: grid;

  grid-template-columns: ${({ isMonthCalendar }) =>
    isMonthCalendar ? "" : "auto 1fr"};

  grid-template-rows: auto;
  grid-auto-rows: minmax(100px, auto);
  width: 100%;

  .top_row {
    position: sticky;
    top: 0;

    background: white;
    z-index: 5;

    border-bottom: 1px solid #eaebed;
  }

  .times_row {
    position: sticky;
    left: 0;

    background: white;
    z-index: 3;

    /*  border-right: 1px solid #eaebed; */
    width: 45px;
    text-align: right;
  }

  .scroll_row {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .empty {
    z-index: 4;

    left: 0;
  }

  .calendar_row {
    z-index: 1;
  }

  #users_row {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  #calendar_row {
    border: 1px solid #d8d8d8;
    border-top: none;
  }
`;

export default CalendarPage;
