import { AddCustomer, PostCustomer2FA } from "@/server-types";
import { useMutation } from "@tanstack/react-query";
import { post } from "../request-helpers";

/**
 * POST /customers
 * /
 */

export const useAddCustomer = () => {
  return useMutation({
    mutationFn: (body: AddCustomer["query"]) =>
      post<AddCustomer>("/customer", body),
  });
};

export const useConfirmCustomer2FA = () => {
  return useMutation({
    mutationFn: ({
      customerId,
      body,
    }: {
      customerId: number;
      body: PostCustomer2FA["body"];
    }) => post<PostCustomer2FA>(`/customer/${customerId}/2fa`, body),
  });
};
